import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Paper, Typography } from '@material-ui/core';
import Image from 'material-ui-image';

import { useDispatch } from 'react-redux';
import history from '~/services/history';
import { resetNewContract } from '~/store/modules/new-contract/actions';

import IlustraContratoConcluido from '~/assets/ilustra_contrato_concluido.svg';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    borderRadius: '8px',
    width: '442px',
    height: '539px',
    flexDirection: 'column',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttonExit: {
    minWidth: '171px',
    borderRadius: '200px',
    marginTop: '20px',
  },
  bodyModal: {
    fontSize: '28px',
    textAlign: 'center',
    fontWeight: 500,
    color: '#47D19D',
  },
  numeroContrato: {
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 800,
    color: '#282D33',
    padding: '20px',
    marginTop: '20px',
    width: '100%',
    borderRadius: '200px',
    background: '#F5F5F5',
    boxShadow: theme.shadows[0],
    maxHeight: '64px',
  },
}));

export default function ContratoSucessoModal({ open }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetNewContract());
    history.push('/visitas');
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Image
              src={IlustraContratoConcluido}
              style={{
                display: 'flex',
                paddingTop: 'unset',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              imageStyle={{
                width: '305',
                height: '220',
                position: 'unset',
              }}
            />
            <Typography className={classes.bodyModal}>
              Contrato concluído com sucesso, seu número é:
            </Typography>
            <Paper className={classes.numeroContrato}>1234567409343</Paper>
            <Button
              variant="contained"
              className={classes.buttonExit}
              color="primary"
              onClick={handleClose}
              fullWidth
            >
              VOLTAR PARA A LISTA
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ContratoSucessoModal.propTypes = {
  open: PropTypes.bool.isRequired,
};
