import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import { darken } from 'polished';

import { eraseSignature } from '~/store/modules/new-contract/actions';

const useStyles = makeStyles(() => ({
  canvas: {
    border: '2px dashed #A1ADEB',
    width: 329,
    height: 200,
  },
  gridCanvas: {
    background: '#F7F8FA',
    borderRadius: '20px',
    padding: '25px',
  },
  gridClean: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  instructions: {
    lineHeight: '18px',
    fontWeight: 400,
    margin: '15px 0',
  },
  btSalvar: {
    marginBottom: '10px',
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  btVoltar: {
    color: '#fff',
    borderRadius: '200px',
    background: '#4D5884',
    '&:hover': {
      background: `${darken(0.1, '#4D5884')}`,
    },
  },
}));

function Pencil({ saveSignature, handleBack }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const assinatura = useSelector(state => state.newContract.assinatura);

  const [penColor /* setPenColor */] = useState('#000000');
  const [padContent, setPadContent] = useState(null);

  // const handleChangePenColor = event => {
  //   setPenColor(event.target.value);
  // };

  const handleSave = () => {
    saveSignature(padContent.getTrimmedCanvas().toDataURL());
  };

  const handleClear = () => {
    dispatch(eraseSignature());
    padContent.clear();
  };

  useEffect(() => {
    if (padContent && assinatura && !assinatura.font) {
      padContent.fromDataURL(assinatura);
    }
  }, [padContent]); // eslint-disable-line

  return (
    <Grid item xs={12} className={classes.gridCanvas}>
      <Grid container>
        <Grid item xs={12}>
          <SignatureCanvas
            penColor={penColor}
            canvasProps={{
              className: classes.canvas,
            }}
            ref={ref => setPadContent(ref)}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <BlackRadio
            checked={penColor === '#000000'}
            onChange={handleChangePenColor}
            value="#000000"
          />
          <BlueRadio
            checked={penColor === '#3834FF'}
            onChange={handleChangePenColor}
            value="#3834FF"
          /> */}
        </Grid>
        <Grid item xs={6} className={classes.gridClean}>
          <Button
            variant="text"
            color="secondary"
            disabled={!padContent}
            onClick={handleClear}
          >
            Limpar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={classes.instructions}
            color="secondary"
          >
            Ao assinar e confirmar você desclara que leu e aceitou todos os
            termos desse contrato
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            className={classes.btSalvar}
            onClick={handleSave}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            fullWidth
            className={classes.btVoltar}
            onClick={() => handleBack()}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

// const BlackRadio = withStyles({
//   root: {
//     color: '#000',
//     '&$checked': {
//       color: '#000',
//     },
//   },
//   checked: {},
// })(props => <Radio color="default" {...props} />);

// const BlueRadio = withStyles({
//   root: {
//     color: '#3834FF',
//     '&$checked': {
//       color: '#3834FF',
//     },
//   },
//   checked: {},
// })(props => <Radio color="default" {...props} />);

Pencil.propTypes = {
  saveSignature: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default Pencil;
