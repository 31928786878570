import React from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  Backdrop,
  Fade,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    width: '350px',
    height: '327px',
    padding: '34px 42px',
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  form: {
    height: '100%',
  },
  tituloModal: {
    color: '#4D5884',
    fontSize: '20px',
  },
  tituloAtividade: {
    fontSize: '19px',
    color: '#50A6FF',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
    fontSize: '10px',
  },
  value: {
    color: '#868CAB',
    fontSize: '14px',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(80, 166, 255, 1)',
  },
  gridFile: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    padding: '11px',
    borderRadius: '8px',
  },
  containerFiles: {
    maxHeight: '90px',
    overflowY: 'auto',
  },
  btEntregar: {
    color: '#fff',
    background: '#50A6FF',
    borderRadius: '200px',
    alignSelf: 'center',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
}));

export default function AnexoName({ handleSave, file }) {
  const classes = useStyles();
  const tiposDocumento = useSelector(state => state.lookup.tipoDocumento);

  const formik = useFormik({
    validationSchema: Yup.object({
      tipo_documento: Yup.string().required(
        'O tipo do documento é obrigatório'
      ),
    }),
    initialValues: {
      tipo_documento: '',
    },
    onSubmit: (values, { resetForm }) => {
      handleSave(values.tipo_documento);
      resetForm();
    },
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={Boolean(file)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Boolean(file)}>
          <div className={classes.paper}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12}>
                  <Typography className={classes.tituloModal}>Anexo</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    color="secondary"
                    variant="filled"
                    fullWidth
                    error={Boolean(
                      formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.escolaridade &&
                        formik.errors.dadosTitular.escolaridade
                    )}
                  >
                    <InputLabel id="select-tipo_documento-filled-label">
                      Nome do Documento
                    </InputLabel>
                    <Select
                      color="secondary"
                      labelId="select-tipo_documento-filled-label"
                      name="tipo_documento"
                      value={formik.values.tipo_documento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>Escolha um nível</em>
                      </MenuItem>
                      {tiposDocumento.map(tipoDocumento => (
                        <MenuItem
                          value={tipoDocumento.descricao}
                          key={tipoDocumento.intkey}
                        >
                          {tipoDocumento.descricao}
                        </MenuItem>
                      ))}
                    </Select>

                    {formik.touched.tipo_documento &&
                      formik.errors.tipo_documento &&
                      formik.touched.tipo_documento &&
                      formik.errors.tipo_documento && (
                        <FormHelperText error>
                          {formik.errors.tipo_documento}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {file && (
                    <div className={classes.divFile}>
                      <Typography className={classes.fileName}>
                        {file.name}
                      </Typography>
                      <Typography>{(file.size / 1000).toFixed(0)}kb</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className={classes.btEntregar}
                  >
                    ANEXAR
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

AnexoName.propTypes = {
  handleSave: PropTypes.func.isRequired,
  file: PropTypes.shape(),
};

AnexoName.defaultProps = {
  file: null,
};
