import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { turmasRequestSuccess, turmasRequestFailure } from './actions';
import { turmasRequest } from '../unidade/actions';

const dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

export function* request() {
  try {
    const response = yield call(api.get, 'turmas');

    const turmas = response.data.map(turma => {
      if (turma.diasaula) {
        const diasAula = turma.diasaula.filter(
          (value, index) => turma.diasaula.indexOf(value) === index
        );

        turma.diasaula = diasAula.map(dia => dias[dia]).join(', ');
      }
      return turma;
    });

    yield put(turmasRequestSuccess(turmas));
  } catch (err) {
    if (err.response) {
      if (
        err.response.data.error ===
        'Ocorreu um erro ao tentar configurar sessão.'
      ) {
        yield put(turmasRequest());
      }
    } else {
      toast.error('Falha na requisição, contacte o suporte.');
      yield put(turmasRequestFailure());
    }
  }
}

export default all([takeLatest('@turmas/TURMAS_REQUEST', request)]);
