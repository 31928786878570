import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import unidade from './unidade/sagas';
import cursos from './cursos/sagas';
import fontes from './fontes/sagas';
import lookup from './lookup/sagas';
import turmas from './turmas/sagas';
import pautas from './pautas/sagas';
import professores from './professores/sagas';
import visitas from './visitas/sagas';
import newContract from './new-contract/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    unidade,
    cursos,
    fontes,
    lookup,
    turmas,
    pautas,
    professores,
    visitas,
    newContract,
  ]);
}
