export function showLoading(message) {
  return {
    type: '@loading/SHOW',
    payload: { message },
  };
}

export function hideLoading() {
  return {
    type: '@loading/HIDE',
  };
}
