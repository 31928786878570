import { Chip, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  chip: {
    margin: '5px',
  },
});

export default function FiltrosAplicados() {
  const classes = useStyles();
  const activedCourses = useSelector(state => state.cursos.actived);
  const filtrosAplicados = useSelector(state => state.newContract.filtrosTurma);
  const statusTurmaLookup = useSelector(state => state.lookup.statusTurma);
  const tipoTurmaLookup = useSelector(state => state.lookup.tipoTurma);

  const renderFilter = filter => {
    if (
      Array.isArray(filtrosAplicados[filter]) &&
      filtrosAplicados[filter].length > 0
    ) {
      if (filter === 'cursos') {
        const cursos = activedCourses.filter(curso =>
          filtrosAplicados.cursos.includes(curso.curso_id)
        );
        return cursos.map(curso => (
          <Chip
            key={curso.curso_id}
            label={`Curso: ${curso.descricao}`}
            color="primary"
            className={classes.chip}
          />
        ));
      }

      if (filter === 'status') {
        const statusTurma = statusTurmaLookup.filter(status =>
          filtrosAplicados.status.includes(status.intkey)
        );
        return statusTurma.map(status => (
          <Chip
            key={status.intkey}
            label={`Status: ${status.descricao}`}
            color="primary"
            className={classes.chip}
          />
        ));
      }

      if (filter === 'tipo') {
        const tipoTurma = tipoTurmaLookup.filter(tipo =>
          filtrosAplicados.tipo.includes(tipo.intkey)
        );
        return tipoTurma.map(tipo => (
          <Chip
            key={tipo.intkey}
            label={`Tipo: ${tipo.descricao}`}
            color="primary"
            className={classes.chip}
          />
        ));
      }

      if (filter === 'conteudos') {
        return <></>;
      }

      return filtrosAplicados[filter].map(filtro => (
        <Chip
          key={filtro}
          label={`Dia: ${filtro}`}
          color="primary"
          className={classes.chip}
        />
      ));
    }

    if (
      typeof filtrosAplicados[filter] === 'string' &&
      Boolean(filtrosAplicados[filter])
    ) {
      return (
        <Chip
          key={filtrosAplicados[filter]}
          label={`Turno: ${filtrosAplicados[filter]}`}
          color="primary"
          className={classes.chip}
        />
      );
    }

    return <></>;
  };

  return (
    <div>
      {Object.keys(filtrosAplicados)
        .reverse()
        .map(filterName => renderFilter(filterName))}
    </div>
  );
}
