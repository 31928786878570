import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import Welcome from '~/assets/welcome.svg';
import WelcomeGo from '~/assets/welcome_go.svg';
import history from '~/services/history';
import api from '~/services/api';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default function Dashboard({ location }) {
  const classes = useStyles();
  const searchParams = useParams();
  const acessos = useSelector(state => state.permissoes.acessos);
  const profile = useSelector(state => state.professor.profile);

  if (location.search) {
    const { code } = searchParams;

    if (code) {
      api.post('youtube/session', { code });
      history.push('video-aula');
    }
  }

  return (
    <div className={classes.body}>
      {acessos && (
        <Image
          src={WelcomeGo}
          style={{
            paddingTop: 'unset',
            background: 'none',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
          }}
        />
      )}
      {!acessos && (
        <Image
          src={Welcome}
          style={{
            paddingTop: 'unset',
            background: 'none',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
          }}
        />
      )}

      <Typography color="primary" variant="h4">
        {(acessos && 'Vamos lá') || `Olá ${profile.nome}, seja bem vindo!`}
      </Typography>
      <Typography color="secondary" variant="h5">
        {(acessos && 'No menu, escolha por onde') ||
          'Crie pautas, atividades e gerencie as turmas de um só lugar'}
      </Typography>
      <Typography color="secondary" variant="h5">
        {(acessos && 'você deseja começar') ||
          'Para começar, selecione uma unidade.'}
      </Typography>
    </div>
  );
}

Dashboard.propTypes = {
  location: PropTypes.shape().isRequired,
};
