import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Typography,
  Button,
  MenuItem,
  FilledInput,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import Select from '~/components/CustomUi/FormSelect';

import api from '~/services/api';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '~/store/modules/loading/actions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    width: '465px',
  },
  divButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: '40px',
  },
  button: {
    borderRadius: '200px',
    color: '#fff',
    minWidth: '140px',
  },
  title: {
    marginBottom: '25px',
  },
  multiselect: {
    maxWidth: '400px',
  },
}));

export default function ModalAtribuirAtividades({ atividade_id }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [turmasSelecionadas, setTurmasSelecionadas] = useState([]);
  const [turmas, setTurmas] = useState([]);

  useEffect(() => {
    async function getTurmasDisponiveis() {
      const response = await api.get(
        `/atividade/${atividade_id}/turmas-disponiveis`
      );

      setTurmas(response.data);
    }

    if (open) {
      getTurmasDisponiveis();
    }
  }, [open]); //eslint-disable-line

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const atribuir = async () => {
    try {
      if (!turmasSelecionadas.length) {
        return;
      }

      dispatch(showLoading('Atribuindo atividade...'));
      await turmasSelecionadas.map(async turma => {
        await api.post(`/turma/${turma}/lancar_atividade/${atividade_id}`);
      });
      dispatch(hideLoading());
      toast.success('Atividade atribuída com sucesso.');
      handleClose();
    } catch (error) {
      dispatch(hideLoading());
      toast.error('Ocorreu um erro ao tentar atribuir atividade.');
      handleClose();
    }
  };

  const renderTurmasSelecionadas = () => {
    const descricoes = [];
    turmas
      .filter(turma => turmasSelecionadas.includes(turma.turma_id))
      .map(turma => descricoes.push(turma.nome));

    return descricoes.join(', ');
  };

  const handleChangeTurma = event => {
    setTurmasSelecionadas(event.target.value);
  };

  return (
    <div>
      <IconButton size="small" onClick={handleOpen}>
        <AddCircle color="primary" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography color="primary" variant="h5" className={classes.title}>
              Atribuir atividade a uma turma
            </Typography>
            <Select
              name="turma"
              label="Turma"
              value={turmasSelecionadas}
              onChange={handleChangeTurma}
              multiple
              input={<FilledInput />}
              renderValue={() => renderTurmasSelecionadas()}
              className={classes.multiselect}
            >
              <MenuItem value="">Selecione</MenuItem>
              {turmas &&
                turmas.map(turma => (
                  <MenuItem
                    value={turma.turma_id}
                    key={Math.random(9999) + turma.turma_id}
                  >
                    <Checkbox
                      checked={turmasSelecionadas.indexOf(turma.turma_id) > -1}
                    />
                    <ListItemText primary={turma.nome} />
                  </MenuItem>
                ))}
            </Select>

            <div className={classes.divButtons}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={atribuir}
                disabled={!turmasSelecionadas.length}
              >
                Atribuir
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalAtribuirAtividades.propTypes = {
  atividade_id: PropTypes.number.isRequired,
};
