import React from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, Paging, Selection } from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { updateLivrosSelecionados } from '~/store/modules/new-contract/actions';

const useStyles = makeStyles({
  root: {
    maxHeight: '350px',
    overflow: 'auto',
  },
});

function TableLivros() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const livros = useSelector(state => state.newContract.livros);
  const dadosContrato = useSelector(state => state.newContract.dadosContrato);

  const onSelectionChanged = data => {
    dispatch(updateLivrosSelecionados(data.selectedRowKeys));
  };

  return (
    <div className={classes.root}>
      <DataGrid
        id="gridContainer"
        dataSource={livros}
        showBorders
        selectedRowKeys={dadosContrato.livrosSelecionados}
        onSelectionChanged={onSelectionChanged}
        noDataText="Nenhumo livro encontrado"
      >
        <Paging enabled={false} />
        <Selection mode="multiple" showCheckBoxesMode="always" />
        <Column dataField="nome" caption="NOME" />
        <Column dataField="curso" caption="CURSO" />
      </DataGrid>
    </div>
  );
}

export default TableLivros;
