import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  FormControl,
  FilledInput,
  Checkbox,
  ListItemText,
  IconButton,
  // InputLabel,
} from '@material-ui/core';
import Image from 'material-ui-image';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import ReactPlayer from 'react-player';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { addMinutes, addHours } from 'date-fns';
import { DeleteOutlineOutlined } from '@material-ui/icons';

import Dialog from '~/components/CustomUi/Dialog';
import api from '~/services/api';
import apiAtividadeStorage from '~/services/apiAtividadeStorage';
import apiVideoStorage from '~/services/apiVideoStorage';
import history from '~/services/history';
import Ilustracao from '~/assets/ilustracao_videoaula.svg';
import Select from '~/components/CustomUi/FormSelect';
import DateTimePickerField from '~/components/CustomUi/DateTimePickerField';
import TextField from '~/components/CustomUi/CustomTextField';
import { aulaSelecionada } from '~/store/modules/transmissao/actions';
import UploadVideo from '~/components/VideoAula/ModalUpload';

import ModalAtividade from './ModalAtividade';
import TableAtividadesAdicionadas from './TableAtividadesAdicionadas';
import TreeviewUnidadesModal from './TreeviewUnidadesModal';

const useStyles = makeStyles({
  ilustracao: {
    display: 'flex',
    justifyContent: 'center',
    background: '#f1f1f1',
    padding: '15px 10px',
    borderRadius: '25px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
  },
  button: {
    color: 'white',
    borderRadius: '200px',
    margin: '0 5px',
  },
  media: {
    display: 'flex',
    flexDirection: 'column',
  },
  status: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '0 0 20px 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btRemover: {
    color: '#50A6FF',
  },
});

export default function Formulario({ live }) {
  const [tipoTransmissaoEscolhida, setTipoTransmissaoEscolhida] = useState(
    live ? 1 : 2
  );

  const [videoUploaded, setVideoUploaded] = useState(null);

  const schema = Yup.object().shape({
    curso: Yup.number().typeError('O curso é obrigatório'),
    turma: Yup.number()
      .positive()
      .required('A turma é obrigatória')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      ),
    materia: Yup.number()
      .positive()
      .required('A matéria é obrigatória')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      ),
    aula: Yup.number()
      .positive()
      .required('A aula é obrigatória')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      ),
    data: Yup.string().required('A data/hora é obrigatória'),
    conteudos: Yup.array(Yup.number()).nullable(true),
    professor: Yup.number()
      .required('O professor é obrigatório')
      .typeError('O professor é obrigatório'),
    tipo: Yup.number()
      .required('O tipo da aula é obrigatório')
      .typeError('O tipo da aula é obrigatório'),
    tipoTransmissao: Yup.number().typeError(
      'O tipo da transmissão é obrigatório'
    ),
    titulo: Yup.string().required('O título é obrigatório'),
    status: Yup.number().typeError(),
    descricao: Yup.string().required('A descrição é obrigatória'),
    url:
      (!live &&
        ![4, 5, 6].includes(tipoTransmissaoEscolhida) &&
        Yup.string().required('A url é obrigatória')) ||
      Yup.string(),
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const cursos = useSelector(state => state.cursos.data);
  const turmas = useSelector(state => state.turmas.data);
  const lookup = useSelector(state => state.lookup);
  const unidade = useSelector(state => state.unidade.unidade_id);
  const unidadesVideoaula = useSelector(
    state => state.permissoes.unidades_videoaula
  );

  const [cursoEscolhido, setCursoEscolhido] = useState(null);
  const [turmaEscolhida, setTurmaEscolhida] = useState(null);
  const [materiaEscolhida, setMateriaEscolhida] = useState(null);
  const [aulaEscolhida, setAulaEscolhida] = useState(null);
  const [statusEscolhido, setStatusEscolhido] = useState('');
  const [conteudosSelecionados, setConteudosSelecionados] = useState([]);
  // const [turmasSelecionadas, setTurmasSelecionados] = useState([]);
  const [unidadesSelecionadas, setUnidadesSelecionadas] = useState(null);

  const [professores, setProfessores] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [conteudos, setConteudos] = useState([]);

  const [urlPreview, setUrlPreview] = useState(null);
  const [dataProximaAula, setDataProximaAula] = useState(null);
  const [modalTransmissao, setModalTransmissao] = useState(false);
  const [showModalAtividade, setShowModalAtividade] = useState(false);
  const [showModalUnidades, setShowModalUnidades] = useState(false);
  const [parametrosAtividades, setParametrosAtividades] = useState({
    cursos: null,
    turmas: null,
    materias: null,
    aulas: null,
  });
  const [showLoading, setShowLoading] = useState(false);
  const [atividadesAdicionadas, setAtividadesAdicionadas] = useState([]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }

    const getProfessores = async () => {
      const response = await api.get('professores');
      setProfessores(response.data);
    };

    if (!professores.length) {
      getProfessores();
    }
  }, [professores]);

  useEffect(() => {
    const getDataAula = async () => {
      const response = await api.get(`data-aula/${turmaEscolhida}`);
      setDataProximaAula(response.data.proxima_aula);
      /* Feature Multiplas Turmas: Substituir código acima pelo código abaixo */
      // if (turmasSelecionadas.length === 1) {
      //   const response = await api.get(`data-aula/${turmasSelecionadas[0]}`);
      //   setDataProximaAula(response.data.proxima_aula);
      // }
    };

    const getMaterias = async () => {
      const response = await api.get(`turma/${turmaEscolhida}/materias`);
      /* Feature Multiplas Turmas: Substituir código acima pelo código abaixo */
      // const response = await api.get('/materias', {
      //   params: turmasSelecionadas,
      // });
      setMaterias(response.data);
    };

    if (turmaEscolhida) {
      getDataAula();
      getMaterias();
    } else {
      setDataProximaAula(null);
      setMaterias([]);
    }
  }, [turmaEscolhida]);

  useEffect(() => {
    const getAulas = async () => {
      const response = await api.get(`materia/${materiaEscolhida}/aulas`);
      setAulas(response.data);
    };

    if (materiaEscolhida) {
      getAulas();
    } else {
      setAulas([]);
    }
  }, [materiaEscolhida]);

  useEffect(() => {
    const getConteudos = async () => {
      const response = await api.get(`aula/${aulaEscolhida}/conteudos`);
      setConteudos(response.data);
    };

    if (aulaEscolhida) {
      getConteudos();
    } else {
      setConteudos([]);
    }
  }, [aulaEscolhida]);

  async function handleSubmit({
    curso,
    turma,
    // turmasEscolhidas,
    materia,
    aula,
    data,
    conteudos: conteudosEscolhidos,
    professor,
    tipo,
    tipoTransmissao,
    titulo,
    status,
    descricao,
    url,
  }) {
    setShowLoading(true);

    const unidadeFormatada = `0000${unidade}`.slice(-5);
    const dataHoraSplitted = data.split(' ');
    const hora = dataHoraSplitted[1];
    const dataSplitted = dataHoraSplitted[0].split('/');
    const dataFormatada = `${dataSplitted[2]}-${dataSplitted[1]}-${dataSplitted[0]} ${hora}:00`;

    if (live && new Date(dataFormatada) < addMinutes(new Date(), 9)) {
      toast.error(
        'Para transmissão de aulas ao vivo, a hora da aula transmitida deve ter no mínimo 10 minutos de antecedência'
      );
      setShowLoading(false);

      return;
    }

    if (
      tipoTransmissaoEscolhida === 4 &&
      new Date(dataFormatada) < addHours(new Date(), 1)
    ) {
      toast.error(
        'Para videoconferência ao vivo, a hora da aula transmitida deve ter no mínimo 1 hora de antecedência'
      );
      setShowLoading(false);

      return;
    }

    if (!!atividadesAdicionadas) {
      await atividadesAdicionadas.map(atividade => {
        if (!!atividade.files) {
          atividade.files.map(arquivo => {
            atividade.anexos.push(arquivo.downloadUrl);
            apiAtividadeStorage.put(
              `${process.env.REACT_APP_ORACLE_URL_UPLOAD_ATIVIDADE}${unidadeFormatada}/atividades/${atividade.atividade_id}/${arquivo.fileName}`,
              arquivo,
              {
                'Content-Type': `${arquivo.type}`,
              }
            );
          });
        }
      });
    }

    if (tipoTransmissaoEscolhida === 3) {
      const hasProtocol = url.includes('http');
      url = (hasProtocol && url) || `https://${url}`;
    }

    const dataPost = {
      curso,
      data: dataFormatada,
      data_timestamp: new Date(dataFormatada).getTime(),
      professor,
      tipo,
      tipoTransmissao,
      titulo,
      status,
      descricao,
      url,
      atividadesAdicionadas,
      unidadesSelecionadas,
    };

    if (turma) {
      dataPost.turma = turma;
    }
    /* Feature Multiplas Turmas: Substituir código acima pelo código abaixo */
    // if (turmasEscolhidas) {
    //   dataPost.turmas = turmasEscolhidas;
    // }

    if (materia) {
      dataPost.materia = materia;
    }

    if (aula) {
      dataPost.aula = aula;
    }

    if (!!conteudosEscolhidos.length) {
      dataPost.conteudos = conteudosEscolhidos;
    }

    await api
      .post('videoaula', dataPost)
      .then(async response => {
        toast.success('Videoaula salva com sucesso.');
        setShowLoading(false);

        const { videoaula_id } = response.data;

        if (videoUploaded && videoaula_id) {
          const fileName = `${new Date().getTime()}`;
          const extension = videoUploaded.type.replace(/\w+\//, '.');
          await apiVideoStorage.put(
            `${unidadeFormatada}%2videoaula%2${videoaula_id}%2${fileName}${extension}`,
            videoUploaded,
            {
              headers: {
                'Content-Type': videoUploaded.type,
              },
            }
          );
        }

        if (live) {
          setModalTransmissao(true);
          dispatch(aulaSelecionada(response.data));
        } else {
          history.push('/video-aula');
        }
      })
      .catch(() => {
        setShowLoading(false);
        toast.error('Erro ao salvar videoaula.');
      });
  }

  const handleChangeCurso = event => {
    setCursoEscolhido(event.target.value);
    setParametrosAtividades({
      cursos: [event.target.value],
      turmas: null,
      materias: null,
      aulas: null,
    });
  };

  const handleChangeTurma = event => {
    setTurmaEscolhida(event.target.value);
    // setTurmasSelecionados(event.target.value);
    setParametrosAtividades({
      ...parametrosAtividades,
      turmas: [event.target.value],
      materias: null,
      aulas: null,
    });
  };

  const handleChangeMateria = event => {
    setMateriaEscolhida(event.target.value);
    setParametrosAtividades({
      ...parametrosAtividades,
      materias: [event.target.value],
      aulas: null,
    });
  };

  const handleChangeAula = event => {
    setAulaEscolhida(event.target.value);
    setParametrosAtividades({
      ...parametrosAtividades,
      aulas: [event.target.value],
    });
  };

  const handleChangeTipoTransmissao = event => {
    setTipoTransmissaoEscolhida(event.target.value);
    if ([4, 5].includes(event.target.value)) {
      setStatusEscolhido(1);
    }
  };

  function handleChangeUrl(url) {
    setUrlPreview(url);
  }

  const handleCloseModalAtividade = () => {
    setShowModalAtividade(false);
  };

  const addAtividade = atividade => {
    return (
      !!atividade &&
      setAtividadesAdicionadas([...atividadesAdicionadas, atividade])
    );
  };

  const removerAtividade = atividade => {
    const index = atividadesAdicionadas.indexOf(atividade);
    const newArray = [...atividadesAdicionadas];
    newArray.splice(index, 1);
    setAtividadesAdicionadas(newArray);
  };

  const handleChangeConteudo = event => {
    setConteudosSelecionados(event.target.value);
  };

  const renderSelecionados = () => {
    const descricoes = [];
    conteudos
      .filter(conteudoFilter =>
        conteudosSelecionados.includes(conteudoFilter.conteudo_id)
      )
      .map(conteudoMapped => descricoes.push(conteudoMapped.descricao));
    return descricoes.join(', ');
  };

  // const renderTurmasSelecionadas = () => {
  //   const descricoes = [];
  //   turmas
  //     .filter(turma => turmasSelecionadas.includes(turma.turma_id))
  //     .map(turma => descricoes.push(turma.nome));
  //   return descricoes.join(', ');
  // };

  const getHandledFileName = () => {
    if (videoUploaded.name.length > 50) {
      return `${videoUploaded.name.substring(
        0,
        20
      )} ... ${videoUploaded.name.substring(videoUploaded.name.length - 20)}`;
    }

    return videoUploaded.name;
  };

  const handleUnidadesSelecionadas = async unidades => {
    await setUnidadesSelecionadas(unidades);
    const element = document.getElementById('btSave');
    element.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <div ref={formRef}>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="secondary" variant="h6" component="p">
              Dados do curso
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Curso"
                    name="curso"
                    placeholder="Selecione"
                    onChange={handleChangeCurso}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {cursos &&
                      cursos
                        .filter(curso => curso.ativo)
                        .map(curso => (
                          <MenuItem key={curso.curso_id} value={curso.curso_id}>
                            {curso.descricao}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Turma"
                    name="turma"
                    onChange={handleChangeTurma}
                    // name="turmasEscolhidas"
                    // labelId="turma-label"
                    // renderValue={() => renderTurmasSelecionadas()}
                    // value={turmasSelecionadas}
                    // multiple
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {turmas &&
                      turmas
                        .filter(turma => turma.curso_id === cursoEscolhido)
                        .map(turma => (
                          <MenuItem key={turma.turma_id} value={turma.turma_id}>
                            {turma.nome}
                            {/* <Checkbox
                              checked={
                                turmasSelecionadas.indexOf(turma.turma_id) > -1
                              }
                            />
                            <ListItemText primary={turma.nome} /> */}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Matéria"
                    name="materia"
                    onChange={handleChangeMateria}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {materias &&
                      materias.map(materia => (
                        <MenuItem
                          key={materia.materia_id}
                          value={materia.materia_id}
                        >
                          {materia.materia}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select label="Aula" name="aula" onChange={handleChangeAula}>
                    <MenuItem value="">Selecione</MenuItem>
                    {aulas &&
                      aulas.map(aula => (
                        <MenuItem
                          key={aula.conteudo_id}
                          value={aula.conteudo_id}
                        >
                          {aula.descricao}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" variant="h6" component="p">
                  Dados da aula
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimePickerField
                  label="Data"
                  name="data"
                  fullWidth
                  disablePast={live}
                  value={dataProximaAula || null}
                  defaultValue={dataProximaAula || null}
                  inputVariant="filled"
                  format="dd/MM/yyyy HH:mm"
                  margin="normal"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={date => {
                    setDataProximaAula(date);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Conteúdo da aula"
                    name="conteudos"
                    value={conteudosSelecionados}
                    multiple
                    input={<FilledInput />}
                    renderValue={() => renderSelecionados()}
                    onChange={handleChangeConteudo}
                  >
                    <MenuItem value={null} disabled>
                      Selecione
                    </MenuItem>
                    {conteudos &&
                      conteudos.map(conteudo => (
                        <MenuItem
                          key={conteudo.conteudo_id}
                          value={conteudo.conteudo_id}
                        >
                          <Checkbox
                            checked={
                              conteudosSelecionados.indexOf(
                                conteudo.conteudo_id
                              ) > -1
                            }
                          />
                          <ListItemText primary={conteudo.descricao} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select label="Professor" name="professor">
                    <MenuItem value="">Selecione</MenuItem>
                    {professores &&
                      professores.map(professor => (
                        <MenuItem
                          key={professor.professor_id}
                          value={professor.professor_id}
                        >
                          {professor.professor}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select label="Tipo da aula" name="tipo">
                    <MenuItem value="">Selecione</MenuItem>
                    {lookup.tipoAula.map(tipo => (
                      <MenuItem key={tipo.intkey} value={tipo.intkey}>
                        {tipo.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Título da aula"
                  name="titulo"
                  placeholder="Digite o título da aula"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição da aula"
                  name="descricao"
                  placeholder="Digite a descrição da aula"
                  multiline
                  rows="3"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" variant="h6" component="p">
                  Atividades dessa aula
                </Typography>
                <TableAtividadesAdicionadas
                  atividades={atividadesAdicionadas}
                  removerAtividade={removerAtividade}
                />
              </Grid>
              {turmaEscolhida && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      setShowModalAtividade(true);
                    }}
                  >
                    Adicionar uma atividade
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.media}>
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth margin="normal">
                <Select
                  label="Tipo de transmissão"
                  name="tipoTransmissao"
                  defaultValue={live ? 1 : 2}
                  disabled={live}
                  onChange={handleChangeTipoTransmissao}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {lookup.tipoTransmissaoVideoAula.map(tipo => (
                    <MenuItem key={tipo.intkey} value={tipo.intkey}>
                      {tipo.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!videoUploaded && tipoTransmissaoEscolhida === 6 && (
              <UploadVideo setFile={setVideoUploaded} />
            )}
            {videoUploaded && tipoTransmissaoEscolhida === 6 && (
              <Grid item xs={12} className={classes.file}>
                <Typography className={classes.fileName}>
                  {getHandledFileName()}
                </Typography>
                <IconButton size="small" onClick={() => setVideoUploaded(null)}>
                  <DeleteOutlineOutlined className={classes.btRemover} />
                </IconButton>
              </Grid>
            )}
            {![4, 5, 6].includes(tipoTransmissaoEscolhida) && (
              <Grid item xs={12}>
                <TextField
                  label="URL do vídeo"
                  name="url"
                  disabled={live}
                  valueChanged={handleChangeUrl}
                />
              </Grid>
            )}
            <Grid item xs={12} className={classes.ilustracao}>
              {(urlPreview && tipoTransmissaoEscolhida !== 3 && (
                <ReactPlayer url={urlPreview} controls />
              )) || (
                <Image
                  src={Ilustracao}
                  style={{
                    paddingTop: 'unset',
                    background: 'none',
                  }}
                  imageStyle={{
                    width: '100%',
                    height: 'unset',
                    position: 'unset',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.status}>
              <FormControl variant="filled" fullWidth margin="normal">
                <Select
                  label="Status de aula"
                  name="status"
                  value={statusEscolhido}
                  defaultValue={statusEscolhido}
                  disabled={tipoTransmissaoEscolhida === 4}
                  onChange={e => {
                    setStatusEscolhido(e.target.value);
                  }}
                >
                  {lookup.videoAulaStatus
                    .filter(
                      tipo =>
                        (tipoTransmissaoEscolhida === 4 && tipo === 1) || tipo
                    )
                    .map(tipo => (
                      <MenuItem key={tipo.intkey} value={tipo.intkey}>
                        {tipo.descricao}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              {(showLoading && (
                <ReactLoading
                  type="cylon"
                  color="#868CAB"
                  className={classes.loading}
                />
              )) || (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={classes.button}
                    onClick={() => {
                      history.push('/videoaulas');
                    }}
                  >
                    Voltar
                  </Button>
                  {unidadesVideoaula && !unidadesSelecionadas && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.button}
                      onClick={() => setShowModalUnidades(true)}
                    >
                      Salvar
                    </Button>
                  )}
                  {(!unidadesVideoaula || unidadesSelecionadas) && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.button}
                      id="btSave"
                    >
                      Salvar
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Form>
      {modalTransmissao && (
        <Dialog
          open={modalTransmissao}
          onClose={() => {
            setModalTransmissao(false);
          }}
          title="Deseja transmitir agora ou deixar para depois?"
          textButtonCancel="Depois"
          textButtonConfirm="Agora"
          onCancel={() => {
            setModalTransmissao(false);
            history.push('/videoaulas');
          }}
          onConfirm={() => {
            setModalTransmissao(false);
            history.push('/transmissao');
          }}
        />
      )}
      {showModalAtividade && Boolean(parametrosAtividades.cursos.length) && (
        <ModalAtividade
          openModal={showModalAtividade}
          handleClose={handleCloseModalAtividade}
          parametrosPesquisa={parametrosAtividades}
          adicionarAtividade={addAtividade}
        />
      )}
      {showModalUnidades && (
        <TreeviewUnidadesModal
          handleUnidadesSelecionadas={handleUnidadesSelecionadas}
        />
      )}
    </div>
  );
}

Formulario.propTypes = {
  live: PropTypes.bool.isRequired,
};
