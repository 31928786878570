import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { applyMask, removeMask } from '~/utils/money';

function MoneyTextField({ onBlur, onChange, ...rest }) {
  const isNumber = keyCode => {
    return (
      (keyCode >= 96 && keyCode <= 105) || (keyCode >= 48 && keyCode <= 57)
    );
  };

  const isBackspace = keyCode => {
    return keyCode === 8;
  };

  const onKeyUpEvent = event => {
    if (!isBackspace(event.keyCode) && !isNumber(event.keyCode)) {
      event.preventDefault();
    }

    const cleanedValue = removeMask(event.target.value);

    event.target.value = applyMask(cleanedValue);
  };

  const handleEventDefaultBehavior = event => {
    event.target.value = applyMask(removeMask(event.target.value));
    return event;
  };

  return (
    <TextField
      type="text"
      onKeyUp={onKeyUpEvent}
      onBlur={e => onBlur(handleEventDefaultBehavior(e))}
      onChange={e => onChange(handleEventDefaultBehavior(e))}
      {...rest}
    />
  );
}

MoneyTextField.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

MoneyTextField.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
};

export default MoneyTextField;
