import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  // Checkbox,
  Fade,
  FormControl,
  // FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { LockOutlined, SwapHoriz } from '@material-ui/icons';
import { differenceInYears } from 'date-fns';
import { toast } from 'react-toastify';
import * as cpf from 'cpf';

import {
  storePersonalData,
  updateLastStepFinished,
  updateStep,
} from '~/store/modules/new-contract/actions';
import LandlineNumber from '~/components/Masks/LandlineNumber';
import PhoneNumber from '~/components/Masks/PhoneNumber';
import Cep from '~/components/Masks/Cep';
import Cpf from '~/components/Masks/Cpf';
import apiViaCep from '~/services/apiViaCep';
import CepSearch from '~/components/CepSearch';
import listStates from '~/utils/list-states';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    padding: '0 15px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleSection: {
    margin: '20px 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
  backdrop: {
    position: 'absolute',
  },
  buttonTitular: {
    minWidth: '171px',
    borderRadius: '200px',
  },
});

function DadosPessoais({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosAluno = useSelector(state => state.newContract.dadosAluno);
  const dadosTitular = useSelector(state => state.newContract.dadosTitular);
  const generos = useSelector(state => state.lookup.generos);
  const escolaridades = useSelector(state => state.lookup.escolaridade);
  const profissoes = useSelector(state => state.lookup.profissoes);
  const lastStepFinished = useSelector(
    state => state.newContract.lastStepFinished
  );
  const [openTitular, setOpenTitular] = React.useState(
    dadosAluno.outro_titular
  );

  const formik = useFormik({
    initialValues: {
      dadosAluno,
      dadosTitular,
    },
    validationSchema: Yup.object().shape({
      dadosAluno: Yup.object()
        .shape(
          {
            outro_titular: Yup.boolean(),
            cgc_cpf: Yup.string()
              .test(
                'test-invalid-cpf',
                'CPF inválido',
                value => !value || cpf.isValid(value)
              )
              .when('outro_titular', {
                is: false,
                then: Yup.string().required('O CPF é obrigatório'),
              }),
            nome: Yup.string().required('O nome é obrigatório'),
            ie_rg: Yup.string(),
            nascimento: Yup.string()
              .matches(
                /(19|20)\d{2}-(0[1-9]|1[0,1,2])-(0[1-9]|[12][0-9]|3[01])/,
                'Data inválida'
              )
              .required('A data de nascimento é obrigatória'),
            sexo: Yup.string().required('O sexo é obrigatório'),
            nomeMae: Yup.string(),
            nomePai: Yup.string(),
            escolaridade: Yup.number().nullable(),
            profissao: Yup.number().nullable(),
            email: Yup.string(),
            telefone: Yup.string().when(
              ['celular', 'telefone_comercial'],
              (celular, telefone_comercial) => {
                if (!celular && !telefone_comercial) {
                  return Yup.string()
                    .matches(/\(\d{2}\)\s\d{4}-\d{4}/, 'Telefone inválido')
                    .required('O telefone é obrigatório');
                }

                return Yup.string().matches(
                  /\(\d{2}\)\s\d{4}-\d{4}/,
                  'Telefone inválido'
                );
              }
            ),
            celular: Yup.string().when(
              ['telefone_comercial', 'telefone'],
              (telefone_comercial, telefone) => {
                if (!telefone_comercial && !telefone) {
                  return Yup.string()
                    .matches(/\(\d{2}\)\s\d{5}-\d{4}/, 'Celular inválido')
                    .required('O celular é obrigatório');
                }

                return Yup.string().matches(
                  /\(\d{2}\)\s\d{5}-\d{4}/,
                  'Celular inválido'
                );
              }
            ),
            comercial: Yup.string().when(
              ['celular', 'telefone'],
              (celular, telefone) => {
                if (!celular && !telefone) {
                  return Yup.string()
                    .matches(/\(\d{2}\)\s\d{4}-\d{4}/, 'Celular inválido')
                    .required('O telefone comercial é obrigatório');
                }

                return Yup.string().matches(
                  /\(\d{2}\)\s\d{4}-\d{4}/,
                  'Telefone comercial inválido'
                );
              }
            ),
            cep: Yup.string()
              .matches(/\d{5}-\d{3}/, 'CEP inválido')
              .required('O CEP é obrigatório'),
            logradouro: Yup.string().required('O endereço é obrigatório'),
            endComplemento: Yup.string(),
            bairro: Yup.string().required('O bairro é obrigatório'),
            localidade: Yup.string().required('A cidade é obrigatória'),
            estado: Yup.string().required('O estado é obrigatório'),
          },
          [
            ['celular', 'telefone_comercial'],
            ['telefone', 'telefone_comercial'],
            ['celular', 'telefone'],
          ]
        )
        .required('Os dados do aluno são obrigatórios'),
      dadosTitular: Yup.object()
        .shape({})
        .when(
          [
            'dadosAluno.nao_possui_cpf',
            'dadosAluno.mae_titular',
            'dadosAluno.pai_titular',
          ],
          {
            is: (naoPossuiCpf, maeTitular, paiTitular) =>
              naoPossuiCpf || maeTitular || paiTitular,
            then: Yup.object()
              .shape(
                {
                  cpf: Yup.string()
                    .test(
                      'test-invalid-cpf',
                      'CPF inválido',
                      value => !value || cpf.isValid(value)
                    )
                    .required('O CPF é obrigatório'),
                  nome: Yup.string().required('O nome é obrigatório'),
                  rg: Yup.string(),
                  nascimento: Yup.string()
                    .matches(
                      /(19|20)\d{2}-(0[1-9]|1[0,1,2])-(0[1-9]|[12][0-9]|3[01])/,
                      'Data inválida'
                    )
                    .required('A data de nascimento é obrigatória'),
                  nome_mae: Yup.string(),
                  nome_pai: Yup.string(),
                  escolaridade: Yup.number().nullable(),
                  profissao: Yup.number().nullable(),
                  email: Yup.string().email('Email inválido'),
                  telefone: Yup.string().when(
                    ['celular', 'telefone_comercial'],
                    (celular, telefone_comercial) => {
                      if (!celular && !telefone_comercial) {
                        return Yup.string()
                          .matches(
                            /\(\d{2}\)\s\d{4}-\d{4}/,
                            'Telefone inválido'
                          )
                          .required('O telefone é obrigatório');
                      }

                      return Yup.string().matches(
                        /\(\d{2}\)\s\d{4}-\d{4}/,
                        'Telefone inválido'
                      );
                    }
                  ),
                  celular: Yup.string().when(
                    ['telefone_comercial', 'telefone'],
                    (telefone_comercial, telefone) => {
                      if (!telefone_comercial && !telefone) {
                        return Yup.string()
                          .matches(/\(\d{2}\)\s\d{5}-\d{4}/, 'Celular inválido')
                          .required('O celular é obrigatório');
                      }

                      return Yup.string().matches(
                        /\(\d{2}\)\s\d{5}-\d{4}/,
                        'Celular inválido'
                      );
                    }
                  ),
                  telefone_comercial: Yup.string().when(
                    ['celular', 'telefone'],
                    (celular, telefone) => {
                      if (!celular && !telefone) {
                        return Yup.string()
                          .matches(/\(\d{2}\)\s\d{4}-\d{4}/, 'Celular inválido')
                          .required('O telefone comercial é obrigatório');
                      }

                      return Yup.string().matches(
                        /\(\d{2}\)\s\d{4}-\d{4}/,
                        'Telefone comercial inválido'
                      );
                    }
                  ),
                  cep: Yup.string()
                    .matches(/\d{5}-\d{3}/, 'CEP inválido')
                    .required('O CEP é obrigatório'),
                  endereco: Yup.string().required('O endereço é obrigatório'),
                  numero_complemento: Yup.string(),
                  bairro: Yup.string().required('O bairro é obrigatório'),
                  cidade: Yup.string().required('A cidade é obrigatória'),
                  estado: Yup.string().required('O estado é obrigatório'),
                },
                [
                  ['celular', 'telefone_comercial'],
                  ['telefone', 'telefone_comercial'],
                  ['celular', 'telefone'],
                ]
              )
              .required('Os dados do titular são obrigatórios'),
          }
        ),
    }),
    onSubmit: values => {
      if (!lastStepFinished || currentStep < lastStepFinished) {
        dispatch(updateLastStepFinished(currentStep));
      }
      dispatch(
        storePersonalData(
          {
            ...values.dadosAluno,
            outro_titular: Boolean(
              values.dadosTitular.cgc_cpf &&
                values.dadosTitular.nome &&
                values.dadosTitular.cep
            ),
          },
          values.dadosTitular
        )
      );
      dispatch(updateStep(currentStep + 1));
    },
  });

  const calculateAge = dob => {
    const age = differenceInYears(new Date(), new Date(`${dob}T00:00:00.000`));
    return age;
  };

  const enableTitular = () =>
    openTitular ||
    (Boolean(formik.values.dadosAluno.nascimento) &&
      calculateAge(formik.values.dadosAluno.nascimento) < 18);

  const handleClickOpenTitular = () => {
    setOpenTitular(true);
  };

  const handleSearchCep = async (event, person) => {
    try {
      const value = event.target.value.replace(/\D/gi, '');
      if (value.length < 8) {
        return;
      }
      const response = await apiViaCep.get(`/${value}/json/`);
      const { data } = response;

      formik.setFieldValue(`${person}.logradouro`, data.logradouro);
      formik.setFieldValue(`${person}.endComplemento`, data.endComplemento);
      formik.setFieldValue(`${person}.bairro`, data.bairro);
      formik.setFieldValue(`${person}.localidade`, data.localidade);
      formik.setFieldValue(`${person}.estado`, data.uf);
    } catch (error) {
      toast.error('Erro ao buscar o CEP');
    }
  };

  const handleBlurParentName = () => {
    if (formik.values.dadosAluno.mae_titular) {
      formik.setFieldValue(
        'dadosTitular.nome',
        formik.values.dadosAluno.nomeMae
      );
    }

    if (formik.values.dadosAluno.pai_titular) {
      formik.setFieldValue(
        'dadosTitular.nome',
        formik.values.dadosAluno.nomePai
      );
    }
  };

  const handleCopyInfo = (from, to, info) => {
    switch (info) {
      case 'endereco':
        formik.setFieldValue(`${to}.cep`, formik.values[from].cep);
        formik.setFieldValue(
          `${to}.logradouro`,
          formik.values[from].logradouro
        );
        formik.setFieldValue(
          `${to}.endComplemento`,
          formik.values[from].endComplemento
        );
        formik.setFieldValue(`${to}.bairro`, formik.values[from].bairro);
        formik.setFieldValue(
          `${to}.localidade`,
          formik.values[from].localidade
        );
        formik.setFieldValue(`${to}.estado`, formik.values[from].estado);
        break;
      case 'contato':
        formik.setFieldValue(`${to}.celular`, formik.values[from].celular);
        formik.setFieldValue(`${to}.comercial`, formik.values[from].comercial);
        formik.setFieldValue(`${to}.telefone`, formik.values[from].telefone);
        formik.setFieldValue(`${to}.email`, formik.values[from].email);
        break;
      default:
    }
  };

  const onAddressChoose = address => {
    formik.setFieldValue(`${address.person}.cep`, address.cep);
    formik.setFieldValue(`${address.person}.logradouro`, address.logradouro);
    formik.setFieldValue(
      `${address.person}.endComplemento`,
      address.endComplemento
    );
    formik.setFieldValue(`${address.person}.bairro`, address.bairro);
    formik.setFieldValue(`${address.person}.localidade`, address.localidade);
    formik.setFieldValue(`${address.person}.estado`, address.uf);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <button
        onClick={() => dispatch(updateStep(currentStep + 1))}
        type="button"
      >
        Passar
      </button> */}
      <Grid container spacing={1} className={classes.mainContainer}>
        <Grid item xs={12} lg={6}>
          <Card variant="outlined" style={{ padding: '10px' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Dados do Aluno
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="dadosAluno.cgc_cpf"
                      label="CPF do aluno"
                      placeholder="Digite o CPF do aluno"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.cgc_cpf}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.cgc_cpf &&
                          formik.errors.dadosAluno.cgc_cpf
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.cgc_cpf &&
                        formik.errors.dadosAluno.cgc_cpf
                      }
                      disabled={formik.values.dadosAluno.nao_possui_cpf}
                      InputProps={{
                        inputComponent: Cpf,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      name="dadosAluno.nome"
                      label="Nome do aluno"
                      placeholder="Digite o nome completo"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.nome}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.nome &&
                          formik.errors.dadosAluno.nome
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.nome &&
                        formik.errors.dadosAluno.nome
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="dadosAluno.ie_rg"
                      label="RG"
                      placeholder="Digite o RG do aluno"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.ie_rg}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.ie_rg &&
                          formik.errors.dadosAluno.ie_rg
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.ie_rg &&
                        formik.errors.dadosAluno.ie_rg
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      type="date"
                      variant="filled"
                      format="dd/MM/yyyy"
                      label="Data de nascimento"
                      name="dadosAluno.nascimento"
                      fullWidth
                      value={formik.values.dadosAluno.nascimento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.nascimento &&
                          formik.errors.dadosAluno.nascimento
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.nascimento &&
                        formik.errors.dadosAluno.nascimento
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.sexo &&
                          formik.errors.dadosAluno.sexo
                      )}
                    >
                      <InputLabel id="select-sexo-filled-label">
                        Sexo
                      </InputLabel>
                      <Select
                        labelId="select-sexo-filled-label"
                        name="dadosAluno.sexo"
                        value={formik.values.dadosAluno.sexo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="">
                          <em>Escolha uma opção</em>
                        </MenuItem>
                        {generos.map(genero => (
                          <MenuItem value={genero.intkey} key={genero.intkey}>
                            {genero.descricao}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.sexo &&
                        formik.errors.dadosAluno.sexo && (
                          <FormHelperText error>
                            {formik.errors.dadosAluno.sexo}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="dadosAluno.nomeMae"
                      label="Nome da mãe"
                      placeholder="Digite o nome completo"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.nomeMae}
                      onChange={formik.handleChange}
                      onBlur={event => {
                        formik.handleBlur(event);
                        handleBlurParentName();
                      }}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.nomeMae &&
                          formik.errors.dadosAluno.nomeMae
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.nomeMae &&
                        formik.errors.dadosAluno.nomeMae
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="dadosAluno.nomePai"
                      label="Nome da pai"
                      placeholder="Digite o nome completo"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.nomePai}
                      onChange={formik.handleChange}
                      onBlur={event => {
                        formik.handleBlur(event);
                        handleBlurParentName();
                      }}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.nomePai &&
                          formik.errors.dadosAluno.nomePai
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.nomePai &&
                        formik.errors.dadosAluno.nomePai
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.titleSection}
                >
                  Dados de contato{' '}
                  {enableTitular() && (
                    <Tooltip title="Utilizar dados de contato para o titular">
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleCopyInfo(
                            'dadosAluno',
                            'dadosTitular',
                            'contato'
                          )
                        }
                        color="primary"
                      >
                        <SwapHoriz fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="dadosAluno.telefone"
                      label="Telefone"
                      placeholder="Digite o telefone com DDD"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.telefone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.telefone &&
                          formik.errors.dadosAluno.telefone
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.telefone &&
                        formik.errors.dadosAluno.telefone
                      }
                      InputProps={{
                        inputComponent: LandlineNumber,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="dadosAluno.celular"
                      label="Celular"
                      placeholder="Digite o celular com DDD"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.celular}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.celular &&
                          formik.errors.dadosAluno.celular
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.celular &&
                        formik.errors.dadosAluno.celular
                      }
                      InputProps={{
                        inputComponent: PhoneNumber,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="dadosAluno.comercial"
                      label="Telefone comercial"
                      placeholder="Digite o telefone com DDD"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.comercial}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.comercial &&
                          formik.errors.dadosAluno.comercial
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.comercial &&
                        formik.errors.dadosAluno.comercial
                      }
                      InputProps={{
                        inputComponent: LandlineNumber,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="dadosAluno.email"
                      label="Email"
                      placeholder="Digite o email do aluno"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.dadosAluno &&
                        Boolean(
                          formik.touched.dadosAluno &&
                            formik.errors.dadosAluno &&
                            formik.touched.dadosAluno.email &&
                            formik.errors.dadosAluno.email
                        )
                      }
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.email &&
                        formik.errors.dadosAluno.email
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.titleSection}
                >
                  Dados de endereço{' '}
                  {enableTitular() && (
                    <Tooltip title="Utilizar dados de endereço para o titular">
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleCopyInfo(
                            'dadosAluno',
                            'dadosTitular',
                            'endereco'
                          )
                        }
                        color="primary"
                      >
                        <SwapHoriz fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={9} md={3}>
                    <TextField
                      name="dadosAluno.cep"
                      label="CEP"
                      placeholder="Digite o CEP do aluno"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.cep}
                      onChange={formik.handleChange}
                      onBlur={event => {
                        formik.handleBlur(event);
                        handleSearchCep(event, 'dadosAluno');
                      }}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.cep &&
                          formik.errors.dadosAluno.cep
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.cep &&
                        formik.errors.dadosAluno.cep
                      }
                      InputProps={{
                        inputComponent: Cep,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={1}>
                    <CepSearch
                      onChoose={onAddressChoose}
                      options={{ person: 'dadosAluno' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="select-indicado-filled-label">
                        Estado
                      </InputLabel>
                      <Select
                        labelId="select-indicado-filled-label"
                        name="dadosAluno.estado"
                        value={formik.values.dadosAluno.estado}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched.dadosAluno &&
                            formik.errors.dadosAluno &&
                            formik.touched.dadosAluno.estado &&
                            formik.errors.dadosAluno.estado
                        )}
                      >
                        <MenuItem value="">
                          <em>Escolha um estado</em>
                        </MenuItem>
                        {listStates.map(estado => (
                          <MenuItem value={estado.value} key={estado.value}>
                            {estado.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.estado &&
                        formik.errors.dadosAluno.estado && (
                          <FormHelperText error>
                            {formik.errors.dadosAluno.estado}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="dadosAluno.localidade"
                      label="Cidade"
                      placeholder="Digite a cidade"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.localidade}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.localidade &&
                          formik.errors.dadosAluno.localidade
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.localidade &&
                        formik.errors.dadosAluno.localidade
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="dadosAluno.bairro"
                      label="Bairro"
                      placeholder="Digite o nome do bairro"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.bairro}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.bairro &&
                          formik.errors.dadosAluno.bairro
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.bairro &&
                        formik.errors.dadosAluno.bairro
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="dadosAluno.logradouro"
                      label="Endereço"
                      placeholder="Digite o endereço"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.logradouro}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.logradouro &&
                          formik.errors.dadosAluno.logradouro
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.logradouro &&
                        formik.errors.dadosAluno.logradouro
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="dadosAluno.endComplemento"
                      label="Número e complemento"
                      placeholder="Ex: Bloco, Apartamento"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosAluno.endComplemento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.endComplemento &&
                          formik.errors.dadosAluno.endComplemento
                      )}
                      helperText={
                        formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.endComplemento &&
                        formik.errors.dadosAluno.endComplemento
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.titleSection}
                >
                  Demais informações
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.escolaridade &&
                          formik.errors.dadosAluno.escolaridade
                      )}
                    >
                      <InputLabel id="select-escolaridade-filled-label">
                        Escolaridade
                      </InputLabel>
                      <Select
                        labelId="select-escolaridade-filled-label"
                        name="dadosAluno.escolaridade"
                        value={formik.values.dadosAluno.escolaridade}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value={null}>
                          <em>Escolha um nível</em>
                        </MenuItem>
                        {escolaridades.map(escolaridade => (
                          <MenuItem
                            value={escolaridade.intkey}
                            key={escolaridade.intkey}
                          >
                            {escolaridade.descricao}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.escolaridade &&
                        formik.errors.dadosAluno.escolaridade && (
                          <FormHelperText error>
                            {formik.errors.dadosAluno.escolaridade}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.dadosAluno &&
                          formik.errors.dadosAluno &&
                          formik.touched.dadosAluno.profissao &&
                          formik.errors.dadosAluno.profissao
                      )}
                    >
                      <InputLabel id="select-profissao-filled-label">
                        Profissão
                      </InputLabel>
                      <Select
                        labelId="select-profissao-filled-label"
                        name="dadosAluno.profissao"
                        value={formik.values.dadosAluno.profissao}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value={null}>
                          <em>Escolha um item</em>
                        </MenuItem>
                        {profissoes.map(profissao => (
                          <MenuItem
                            value={profissao.intkey}
                            key={profissao.intkey}
                          >
                            {profissao.descricao}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.dadosAluno &&
                        formik.errors.dadosAluno &&
                        formik.touched.dadosAluno.profissao &&
                        formik.errors.dadosAluno.profissao && (
                          <FormHelperText error>
                            {formik.errors.dadosAluno.profissao}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card
            variant="outlined"
            style={{ position: 'relative', padding: '10px' }}
          >
            {!enableTitular() && (
              <Fade in={!enableTitular()} timeout={0.5}>
                <Courtain>
                  <LockOutlined fontSize="large" />
                  <Button
                    variant="contained"
                    onClick={handleClickOpenTitular}
                    className={classes.buttonTitular}
                    color="primary"
                  >
                    Definir titular
                  </Button>
                </Courtain>
              </Fade>
            )}

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary">
                  Dados do Titular
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.cgc_cpf"
                      label="CPF do titular"
                      placeholder="Digite o CPF do titular"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.cgc_cpf}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.cgc_cpf &&
                          formik.errors.dadosTitular.cgc_cpf
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.cgc_cpf &&
                        formik.errors.dadosTitular.cgc_cpf
                      }
                      InputProps={{
                        inputComponent: Cpf,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.nome"
                      label="Nome do titular"
                      placeholder="Digite o nome completo"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.nome}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.nome &&
                          formik.errors.dadosTitular.nome
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.nome &&
                        formik.errors.dadosTitular.nome
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.ie_rg"
                      label="RG"
                      placeholder="Digite o RG do titular"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.ie_rg}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.ie_rg &&
                          formik.errors.dadosTitular.ie_rg
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.ie_rg &&
                        formik.errors.dadosTitular.ie_rg
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="date"
                      color="secondary"
                      variant="filled"
                      format="dd/MM/yyyy"
                      label="Data de nascimento"
                      name="dadosTitular.nascimento"
                      fullWidth
                      value={formik.values.dadosTitular.nascimento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.nascimento &&
                          formik.errors.dadosTitular.nascimento
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.nascimento &&
                        formik.errors.dadosTitular.nascimento
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.nomeMae"
                      label="Nome da mãe"
                      placeholder="Digite o nome completo"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.nomeMae}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.nomeMae &&
                          formik.errors.dadosTitular.nomeMae
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.nomeMae &&
                        formik.errors.dadosTitular.nomeMae
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.nomePai"
                      label="Nome da pai"
                      placeholder="Digite o nome completo"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.nomePai}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.nomePai &&
                          formik.errors.dadosTitular.nomePai
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.nomePai &&
                        formik.errors.dadosTitular.nomePai
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.titleSection}
                >
                  Dados de contato
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.telefone"
                      label="Telefone"
                      placeholder="Digite o telefone com DDD"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.telefone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.telefone &&
                          formik.errors.dadosTitular.telefone
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.telefone &&
                        formik.errors.dadosTitular.telefone
                      }
                      InputProps={{
                        inputComponent: LandlineNumber,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.celular"
                      label="Celular"
                      placeholder="Digite o celular com DDD"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.celular}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.celular &&
                          formik.errors.dadosTitular.celular
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.celular &&
                        formik.errors.dadosTitular.celular
                      }
                      InputProps={{
                        inputComponent: PhoneNumber,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.comercial"
                      label="Telefone comercial"
                      placeholder="Digite o telefone com DDD"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.comercial}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.comercial &&
                          formik.errors.dadosTitular.comercial
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.comercial &&
                        formik.errors.dadosTitular.comercial
                      }
                      InputProps={{
                        inputComponent: LandlineNumber,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.email"
                      label="Email"
                      placeholder="Digite o email do titular"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.email &&
                          formik.errors.dadosTitular.email
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.email &&
                        formik.errors.dadosTitular.email
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.titleSection}
                >
                  Dados de endereço
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={9} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.cep"
                      label="CEP"
                      placeholder="Digite o CEP do titular"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.cep}
                      onChange={formik.handleChange}
                      onBlur={event => {
                        formik.handleBlur(event);
                        handleSearchCep(event, 'dadosTitular');
                      }}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.cep &&
                          formik.errors.dadosTitular.cep
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.cep &&
                        formik.errors.dadosTitular.cep
                      }
                      InputProps={{ inputComponent: Cep }}
                    />
                  </Grid>
                  <Grid item xs={3} md={1}>
                    <CepSearch
                      onChoose={onAddressChoose}
                      options={{ person: 'dadosTitular' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="filled" fullWidth color="secondary">
                      <InputLabel id="select-indicado-filled-label">
                        Estado
                      </InputLabel>
                      <Select
                        color="secondary"
                        labelId="select-indicado-filled-label"
                        name="dadosTitular.estado"
                        value={formik.values.dadosTitular.estado}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched.dadosTitular &&
                            formik.errors.dadosTitular &&
                            formik.touched.dadosTitular.estado &&
                            formik.errors.dadosTitular.estado
                        )}
                      >
                        <MenuItem value="">
                          <em>Escolha um estado</em>
                        </MenuItem>
                        {listStates.map(estado => (
                          <MenuItem value={estado.value} key={estado.value}>
                            {estado.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.estado &&
                        formik.errors.dadosTitular.estado && (
                          <FormHelperText error>
                            {formik.errors.dadosTitular.estado}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.localidade"
                      label="Cidade"
                      placeholder="Digite a cidade"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.localidade}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.localidade &&
                          formik.errors.dadosTitular.localidade
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.localidade &&
                        formik.errors.dadosTitular.localidade
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.bairro"
                      label="Bairro"
                      placeholder="Digite o nome do bairro"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.bairro}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.bairro &&
                          formik.errors.dadosTitular.bairro
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.bairro &&
                        formik.errors.dadosTitular.bairro
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.logradouro"
                      label="Endereço"
                      placeholder="Digite o endereço"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.logradouro}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.logradouro &&
                          formik.errors.dadosTitular.logradouro
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.logradouro &&
                        formik.errors.dadosTitular.logradouro
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      color="secondary"
                      name="dadosTitular.endComplemento"
                      label="Número e complemento"
                      placeholder="Ex: Bloco, Apartamento"
                      variant="filled"
                      fullWidth
                      value={formik.values.dadosTitular.endComplemento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.endComplemento &&
                          formik.errors.dadosTitular.endComplemento
                      )}
                      helperText={
                        formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.endComplemento &&
                        formik.errors.dadosTitular.endComplemento
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.titleSection}
                >
                  Demais informações
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      color="secondary"
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.escolaridade &&
                          formik.errors.dadosTitular.escolaridade
                      )}
                    >
                      <InputLabel id="select-escolaridade-filled-label">
                        Escolaridade
                      </InputLabel>
                      <Select
                        color="secondary"
                        labelId="select-escolaridade-filled-label"
                        name="dadosTitular.escolaridade"
                        value={formik.values.dadosTitular.escolaridade}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="">
                          <em>Escolha um nível</em>
                        </MenuItem>
                        {escolaridades.map(escolaridade => (
                          <MenuItem
                            value={escolaridade.intkey}
                            key={escolaridade.intkey}
                          >
                            {escolaridade.descricao}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.escolaridade &&
                        formik.errors.dadosTitular.escolaridade && (
                          <FormHelperText error>
                            {formik.errors.dadosTitular.escolaridade}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      color="secondary"
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.dadosTitular &&
                          formik.errors.dadosTitular &&
                          formik.touched.dadosTitular.profissao &&
                          formik.errors.dadosTitular.profissao
                      )}
                    >
                      <InputLabel id="select-profissao-filled-label">
                        Profissão
                      </InputLabel>
                      <Select
                        color="secondary"
                        labelId="select-profissao-filled-label"
                        name="dadosTitular.profissao"
                        value={formik.values.dadosTitular.profissao}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="">
                          <em>Escolha um item</em>
                        </MenuItem>
                        {profissoes.map(profissao => (
                          <MenuItem
                            value={profissao.intkey}
                            key={profissao.intkey}
                          >
                            {profissao.descricao}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.dadosTitular &&
                        formik.errors.dadosTitular &&
                        formik.touched.dadosTitular.profissao &&
                        formik.errors.dadosTitular.profissao && (
                          <FormHelperText error>
                            {formik.errors.dadosTitular.profissao}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
            color="primary"
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const Courtain = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5 !important;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #f1f1f1;
  padding: 10px;
`;

DadosPessoais.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default DadosPessoais;
