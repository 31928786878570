import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
// import { DataGrid } from 'devextreme-react';
// import { Column } from 'devextreme-react/data-grid';

import {
  addInstallment,
  loadPaymentPlanDetails,
  loadPaymentPlans,
  storeFinancialData,
  updateStep,
} from '~/store/modules/new-contract/actions';
import { showLoading } from '~/store/modules/loading/actions';
import { toast } from 'react-toastify';
import PlanoPagamentoForm from './PlanoPagamentoForm';
import PlanoPagamentoGrid from './PlanoPagamentoGrid';
import { schemaValidation } from './schema-validation';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleSection: {
    margin: '20px 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '8px',
  },
  gridForm: {
    position: 'relative',
    padding: '20px',
  },
  titleBolsa: {
    margin: '20px 0',
  },
});

const dadosPagamento = {
  valor: '0',
  data: new Date(),
  emreais: false,
  desconto: '0',
  quantidade: 0,
};

const dadosFinanceiroInitialState = {
  planopagamento_id: null,
  matricula: [
    {
      ...dadosPagamento,
      type: 'matricula',
    },
  ],
  material: [
    {
      ...dadosPagamento,
      type: 'material',
    },
  ],
  parcela: [
    {
      ...dadosPagamento,
      demaisvencimentos: null,
      diautil: true,
      type: 'parcela',
    },
  ],
};

const TIPO_CONTRATO_BOLSA = 3;

function Financeiro({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosContrato = useSelector(state => state.newContract.dadosContrato);
  const dadosFinanceiro = useSelector(
    state => state.newContract.dadosFinanceiro
  );
  const paymentPlans = useSelector(state => state.newContract.paymentPlans);
  const [refresh, setRefresh] = React.useState(false);

  const formik = useFormik({
    initialValues: dadosFinanceiroInitialState,
    onSubmit: async values => {
      const schema = schemaValidation(
        dadosFinanceiro.matricula.descontomaximo,
        dadosFinanceiro.parcela.descontomaximo,
        dadosFinanceiro.material.descontomaximo
      );

      if (!(await schema.isValid(values))) {
        toast.error('Preencha todos os campos obrigatórios');
        return;
      }

      dispatch(storeFinancialData(values));
      dispatch(updateStep(currentStep + 1));
    },
  });

  const handleChangePlan = event => {
    formik.handleChange(event);
    dispatch(showLoading('Carregando detalhes do plano de pagamento...'));
    dispatch(loadPaymentPlanDetails(event.target.value));
    setRefresh(!refresh);
  };

  React.useEffect(() => {
    if (!paymentPlans) {
      dispatch(showLoading('Carregando planos de pagamento...'));
      dispatch(loadPaymentPlans(dadosContrato.cursos));
    }
  }, [paymentPlans]); // eslint-disable-line

  React.useEffect(() => {
    formik.setValues({ ...dadosFinanceiro });
  }, [dadosFinanceiro]); // eslint-disable-line

  const handleAddRow = type => {
    const newRow = {
      valor: '0',
      data: '',
      emreais: false,
      desconto: '0',
      quantidade: 0,
      type,
    };

    if (type === 'parcela') {
      newRow.demaisvencimentos = null;
      newRow.diautil = true;
    }

    const installments = [...formik.values[type]];

    installments.push(newRow);

    dispatch(addInstallment({ ...dadosFinanceiro, [type]: installments }));
    formik.setValues({ ...dadosFinanceiro, [type]: installments });
  };

  const handleRemoveRow = (type, index) => {
    const installments = [...formik.values[type]];
    installments.splice(index, 1);
    dispatch(addInstallment({ ...dadosFinanceiro, [type]: installments }));
    formik.setValues({ ...dadosFinanceiro, [type]: installments });
  };

  return (
    <>
      {dadosContrato.tipo_contrato === TIPO_CONTRATO_BOLSA && (
        <Typography
          variant="h6"
          color="secondary"
          align="center"
          className={classes.titleBolsa}
        >
          O modelo de contrato selecionado não possui plano de pagamento.
        </Typography>
      )}

      {dadosContrato.tipo_contrato !== TIPO_CONTRATO_BOLSA && (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} className={classes.mainContainer}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.titleSection}>
                  <Typography variant="body1" color="primary">
                    Dados sobre o contrato
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="filled"
                    fullWidth
                    error={
                      formik.touched.planopagamento_id &&
                      Boolean(formik.errors.planopagamento_id)
                    }
                  >
                    <InputLabel id="select-banco-filled-label">
                      Plano de pagamento
                    </InputLabel>
                    <Select
                      labelId="select-banco-filled-label"
                      name="planopagamento_id"
                      value={formik.values.planopagamento_id}
                      onChange={handleChangePlan}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>Escolha uma opção</em>
                      </MenuItem>
                      {paymentPlans &&
                        paymentPlans.length > 0 &&
                        paymentPlans.map(item => (
                          <MenuItem
                            key={item.planopagamento_id}
                            value={item.planopagamento_id}
                          >
                            {item.descricao}
                          </MenuItem>
                        ))}
                    </Select>

                    {formik.touched.planopagamento_id &&
                      formik.errors.planopagamento_id && (
                        <FormHelperText error>
                          {formik.errors.planopagamento_id}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                {dadosFinanceiro &&
                  (formik.values.planopagamento_id ||
                    !paymentPlans ||
                    !paymentPlans.length) && (
                    <Grid container spacing={1} className={classes.gridForm}>
                      {dadosFinanceiro.matricula.map((_, index) => (
                        <PlanoPagamentoForm
                          indice={index}
                          formik={formik}
                          section="matricula"
                          sectionTitle="Matrícula"
                    key={`matricula_${index}`} // eslint-disable-line
                          handleAddRow={handleAddRow}
                          handleRemoveRow={handleRemoveRow}
                        />
                      ))}
                      <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                      </Grid>
                      {dadosFinanceiro.parcela.map((_, index) => (
                        <PlanoPagamentoForm
                          indice={index}
                          formik={formik}
                          section="parcela"
                          sectionTitle="Parcela"
                    key={`parcela_${index}`} // eslint-disable-line
                          handleAddRow={handleAddRow}
                          handleRemoveRow={handleRemoveRow}
                        />
                      ))}
                      <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                      </Grid>
                      {dadosFinanceiro.material.map((_, index) => (
                        <PlanoPagamentoForm
                          indice={index}
                          formik={formik}
                          section="material"
                          sectionTitle="Material"
                    key={`material_${index}`} // eslint-disable-line
                          handleAddRow={handleAddRow}
                          handleRemoveRow={handleRemoveRow}
                        />
                      ))}
                    </Grid>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12} className={classes.titleSection}>
                  <Typography variant="body1" color="primary">
                    Situação Financeira
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PlanoPagamentoGrid planoPagamento={formik.values} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button
                type="button"
                variant="text"
                className={classes.button}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => dispatch(updateStep(currentStep - 1))}
                variant="contained"
                className={classes.button}
                color="secondary"
              >
                Anterior
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                color="primary"
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}

Financeiro.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Financeiro;
