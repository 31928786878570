import produce from 'immer';

const INITIAL_STATE = {
  showing: false,
  message: null,
};

export default function loading(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@loading/SHOW': {
        draft.showing = true;
        draft.message = action.payload.message;
        break;
      }
      case '@auth/SIGN_OUT':
      case '@loading/HIDE': {
        draft.showing = false;
        draft.message = null;
        break;
      }
      default:
    }
  });
}
