import * as Yup from 'yup';

const commonProperties = (descontoMaximo, required) => ({
  valor: required ? Yup.string().required('Valor é obrigatório') : Yup.string(),
  data: required
    ? Yup.string()
        .matches(
          /(19|20)\d{2}-(0[1-9]|1[0,1,2])-(0[1-9]|[12][0-9]|3[01])/,
          'Data inválida'
        )
        .required('Data é obrigatória')
    : Yup.string(),
  emreais: required
    ? Yup.boolean().required('Em reais é obrigatório')
    : Yup.boolean(),
  desconto: Yup.number()
    .transform((value, originalValue) => {
      return Number.isNaN(originalValue)
        ? undefined
        : parseFloat(originalValue);
    })
    .max(descontoMaximo || 0, 'Valor máximo de desconto excedido')
    .nullable(),
  quantidade: required
    ? Yup.number()
        .integer()
        .required('Quantidade é obrigatória')
    : Yup.number().integer(),
});

export const schemaValidation = (
  descontoMaximoMatricula,
  descontoMaximoParcela,
  descontoMaximoMaterial
) =>
  Yup.object().shape({
    planopagamento_id: Yup.number().nullable(),
    parcela: Yup.array().of(
      Yup.object().shape({
        ...commonProperties(descontoMaximoParcela, true),
        demaisvencimentos: Yup.number()
          .min(1)
          .max(31)
          .nullable(),
        diautil: Yup.bool(),
      })
    ),
    matricula: Yup.array()
      .of(
        Yup.object()
          .shape({
            ...commonProperties(descontoMaximoMatricula, false),
          })
          .optional()
      )
      .optional(),
    material: Yup.array()
      .of(
        Yup.object()
          .shape({
            ...commonProperties(descontoMaximoMaterial, false),
          })
          .optional()
      )
      .optional(),
  });
