export function requestVisitas() {
  return {
    type: '@visitas/GET_VISITAS_REQUEST',
  };
}

export function successRequestVisitas(data) {
  return {
    type: '@visitas/GET_VISITAS_SUCCESS',
    payload: { data },
  };
}

export function failureRequestVisitas() {
  return {
    type: '@visitas/GET_VISITAS_FAILURE',
  };
}
