export const removeMask = maskedValue => {
  if (!maskedValue) {
    return '0.00';
  }

  const cleanedValue = maskedValue.replace(/\D/g, '');
  const value = parseFloat(cleanedValue);

  return (value / 100).toFixed(2);
};

export const applyMask = cleanedValue => {
  return Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(cleanedValue);
};
