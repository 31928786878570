import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    marginTop: '10px',
    color: '#fff',
  },
}));

export default function Loading() {
  const classes = useStyles();
  const loading = useSelector(state => state.loading);

  return (
    <Backdrop className={classes.backdrop} open={loading.showing}>
      <CircularProgress />
      {loading.message && (
        <Typography className={classes.description}>
          {loading.message}
        </Typography>
      )}
    </Backdrop>
  );
}
