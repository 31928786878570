import React from 'react';
import { Switch } from 'react-router-dom';

import SignIn from '~/pages/SignIn';
import Dashboard from '~/pages/Dashboard';
import Contratos from '~/pages/Contratos';
import Turmas from '~/pages/Turmas';
import Atividades from '~/pages/Atividades';
import Pautas from '~/pages/Pautas';
import Aula from '~/pages/Aula';
import Visitas from '~/pages/Visitas';
import VideoAula from '~/pages/VideoAula';
import Transmissao from '~/pages/Transmissao';

import NovaVideoAula from '~/pages/VideoAula/Novo';
import EditarVideoAula from '~/pages/VideoAula/Editar';
import NovoContrato from '~/pages/NovoContrato';

import Route from './Route';

export default function AppRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route
        path="/dashboard"
        exact
        component={Dashboard}
        isPrivate
        isDashboard
      />
      <Route
        path="/contratos"
        exact
        component={Contratos}
        acesso="contratos"
        isPrivate
      />
      <Route
        path="/turmas"
        exact
        component={Turmas}
        acesso="turmas"
        isPrivate
      />
      <Route
        path="/atividades"
        exact
        component={Atividades}
        acesso="atividades"
        isPrivate
      />
      <Route path="/pautas" exact component={Pautas} acesso="pauta" isPrivate />
      <Route path="/aula" exact component={Aula} acesso="contratos" isPrivate />
      <Route
        path="/visitas"
        exact
        component={Visitas}
        acesso="visitas"
        isPrivate
      />
      <Route path="/videoaulas" exact component={VideoAula} isPrivate />
      <Route
        path="/nova-video-aula/:live"
        exact
        component={NovaVideoAula}
        isPrivate
      />
      <Route
        path="/editar-video-aula/:id"
        exact
        component={EditarVideoAula}
        isPrivate
      />
      <Route path="/novo-contrato" exact component={NovoContrato} isPrivate />
      <Route path="/transmissao" exact component={Transmissao} isPrivate />
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
