import React, { useEffect } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  GroupPanel,
  Grouping,
  Pager,
  Paging,
  Button as DxButton,
} from 'devextreme-react/data-grid';

import CustomBreadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import { requestVisitas } from '~/store/modules/visitas/actions';
import history from '~/services/history';
import { storeVisitaData } from '~/store/modules/new-contract/actions';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
    color: '#4D5884',
  },
  novoVideo: {
    color: '#fff',
  },
  boxGrid: {
    padding: '20px 30px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  btContrato: {
    background: theme.palette.success.main,
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
}));

export default function Visitas() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const visitas = useSelector(state => state.visitas.data);

  useEffect(() => {
    if (!visitas) {
      dispatch(requestVisitas());
    }
  }, [visitas]); // eslint-disable-line

  const handleClickGerarContrato = visita => {
    dispatch(storeVisitaData(visita));
    history.push('/novo-contrato');
  };

  return (
    <>
      <CustomBreadcrumbs categorias={['Comercial']} funcionalidade="Visitas" />
      <div className={classes.title}>
        <Typography variant="h5">Visitas</Typography>

        {/* <button type="button" onClick={() => history.push('/novo-contrato')}>
          Teste
        </button> */}
      </div>
      <Box className={classes.boxGrid}>
        <DataGrid
          dataSource={visitas}
          allowColumnReordering
          showBorders
          hoverStateEnabled
          noDataText="Nenhuma visita encontrada"
        >
          <GroupPanel visible emptyPanelText="" />
          <Grouping autoExpandAll={false} />
          <Paging defaultPageSize={200} />
          <Pager showInfo infoText="Página {0} de {1} ({2} visitas)" />

          <Column dataField="apelido" caption="APELIDO" />
          <Column dataField="descricao" caption="DESCRIÇÃO" width={140} />
          <Column dataField="data" caption="DATA" width={150} />
          <Column dataField="retorno" caption="RETORNO" />
          <Column dataField="agendamento" caption="AGENDAMENTO" width={150} />
          <Column dataField="curso" caption="CURSO" />
          <Column dataField="fonte" caption="FONTE" />
          <Column dataField="celular" caption="CELULAR" width={140} />
          <Column dataField="telefone" caption="TELEFONE" width={130} />
          <Column dataField="temcontrato" caption="TEM CONTRATO" width={120} />

          <Column type="buttons" caption="CONTRATO">
            <DxButton
              render={e => {
                if (!e.data.temcontrato) {
                  return (
                    <Button
                      className={classes.btContrato}
                      variant="contained"
                      onClick={() => {
                        handleClickGerarContrato(e.data);
                      }}
                    >
                      Gerar
                    </Button>
                  );
                }
              }}
            />
          </Column>
        </DataGrid>
      </Box>
    </>
  );
}
