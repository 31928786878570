import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowForward, Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  Button as DxButton,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';

import listStates from '~/utils/list-states';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '~/store/modules/loading/actions';
import { searchCep } from './service';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  content: {
    maxWidth: 500,
  },
  submit: {
    marginBottom: '10px',
  },
});

function CepSearch({ onChoose, options }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [logradouros, setLogradouros] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      estado: '',
      cidade: '',
      logradouro: '',
    },
    validationSchema: Yup.object({
      estado: Yup.string().required('O estado é obrigatório'),
      cidade: Yup.string().required('A cidade é obrigatória'),
      logradouro: Yup.string().required('O logradouro é obrigatório'),
    }),
    onSubmit: async values => {
      dispatch(showLoading({ message: 'Carregando...' }));
      setLogradouros(await searchCep(values));
      dispatch(hideLoading());
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const enableSubmit = () => {
    return (
      formik.values.estado && formik.values.cidade && formik.values.logradouro
    );
  };

  const handleChoose = data => {
    onChoose({ ...options, ...data });
    setLogradouros([]);
    formik.resetForm();
    handleClose();
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <Link component="button" variant="body2" onClick={handleClickOpen}>
        Não sei o CEP
      </Link>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle onClose={handleClose}>Pesquisar CEP</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            Preencha as informações abaixo para encontrar o CEP
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <FormControl
              variant="filled"
              fullWidth
              color="secondary"
              size="small"
              margin="normal"
            >
              <InputLabel id="select-estado-filled-label">Estado</InputLabel>
              <Select
                labelId="select-estado-filled-label"
                name="estado"
                value={formik.values.estado}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.estado && formik.errors.estado)}
              >
                <MenuItem value="">
                  <em>Escolha um estado</em>
                </MenuItem>
                {listStates.map(estado => (
                  <MenuItem value={estado.value} key={estado.value}>
                    {estado.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.estado && formik.errors.estado && (
                <FormHelperText error>{formik.errors.estado}</FormHelperText>
              )}
            </FormControl>
            <TextField
              name="cidade"
              label="Cidade"
              variant="filled"
              fullWidth
              margin="normal"
              size="small"
              value={formik.values.cidade}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cidade && formik.errors.cidade}
              helperText={formik.touched.cidade && formik.errors.cidade}
            />
            <TextField
              name="logradouro"
              label="Logradouro"
              placeholder="Pode ser preenchido com parte do endereço"
              variant="filled"
              fullWidth
              margin="normal"
              size="small"
              value={formik.values.logradouro}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.logradouro && formik.errors.logradouro}
              helperText={formik.touched.logradouro && formik.errors.logradouro}
            />
            {enableSubmit() && (
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                className={classes.submit}
              >
                Pesquisar
              </Button>
            )}
          </form>
          <DataGrid
            dataSource={logradouros}
            showBorders
            columnAutoWidth
            noDataText="Nenhum CEP encontrado"
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={logradouros.length > 0}
              showInfo={logradouros.length > 0}
              showNavigationButtons
              infoText="Página {0} de {1} ({2} itens)"
            />
            <Column dataField="cep" caption="CEP" />
            <Column dataField="logradouro" caption="Logradouro" />
            <Column dataField="bairro" caption="Bairro" />
            <Column type="buttons" caption="Selecionar">
              <DxButton
                render={e => {
                  return (
                    <IconButton
                      onClick={() => handleChoose({ ...options, ...e.data })}
                      size="small"
                    >
                      <ArrowForward color="primary" />
                    </IconButton>
                  );
                }}
              />
            </Column>
          </DataGrid>
        </DialogContent>
      </Dialog>
    </>
  );
}

CepSearch.propTypes = {
  onChoose: PropTypes.func.isRequired,
  options: PropTypes.shape(),
};

CepSearch.defaultProps = {
  options: {},
};

export default CepSearch;
