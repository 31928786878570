import { toast } from 'react-toastify';
import apiViaCep from '~/services/apiViaCep';

async function searchCep({ estado, cidade, logradouro }) {
  try {
    const response = await apiViaCep.get(
      `/${estado}/${cidade}/${logradouro}/json/`
    );
    return response.data;
  } catch (error) {
    toast.error('Erro ao buscar CEP');
    return [];
  }
}

export { searchCep };
