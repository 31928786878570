export function updateStep(step) {
  return {
    type: '@newContract/UPDATE_STEP',
    payload: { step },
  };
}

export function updateLastStepFinished(step) {
  return {
    type: '@newContract/UPDATE_LAST_STEP_FINISHED',
    payload: { step },
  };
}

export function storePersonalData(aluno, titular) {
  return {
    type: '@newContract/STORE_PERSONAL_DATA',
    payload: { aluno, titular },
  };
}

export function storeContractData(data) {
  return {
    type: '@newContract/STORE_CONTRACT_DATA',
    payload: data,
  };
}

export function storeClassData(data) {
  return {
    type: '@newContract/STORE_CLASS_DATA',
    payload: data,
  };
}

export function storeFinancialData(data) {
  return {
    type: '@newContract/STORE_FINANCIAL_DATA',
    payload: data,
  };
}

export function storeObservationData(data) {
  return {
    type: '@newContract/STORE_OBSERVATION_DATA',
    payload: data,
  };
}

export function resetNewContract() {
  return {
    type: '@newContract/RESET_NEW_CONTRACT',
  };
}

export function storeVisitaData(data) {
  return {
    type: '@newContract/STORE_VISITA_DATA',
    payload: data,
  };
}

export function addAnexo(data) {
  return {
    type: '@newContract/ADD_ANEXO',
    payload: data,
  };
}

export function deleteAnexo(data) {
  return {
    type: '@newContract/DELETE_ANEXO',
    payload: data,
  };
}

export function registerSignature(assinatura) {
  return {
    type: '@newContract/SAVE_SIGNATURE',
    payload: { assinatura },
  };
}

export function eraseSignature() {
  return {
    type: '@newContract/ERASE_SIGNATURE',
  };
}

export function storeMaterias(data) {
  return {
    type: '@newContract/STORE_MATERIAS',
    payload: data,
  };
}

export function storeLivros(data) {
  return {
    type: '@newContract/STORE_LIVROS',
    payload: data,
  };
}

export function storeMateriasGratuitas(data) {
  return {
    type: '@newContract/STORE_MATERIAS_GRATUITAS',
    payload: data,
  };
}

export function updateMateriasSelecionadas(data) {
  return {
    type: '@newContract/UPDATE_MATERIAS_SELECIONADAS',
    payload: data,
  };
}

export function updateLivrosSelecionados(data) {
  return {
    type: '@newContract/UPDATE_LIVROS_SELECIONADOS',
    payload: data,
  };
}

export function updateFiltrosTurma(data) {
  return {
    type: '@newContract/UPDATE_FILTROS_TURMA',
    payload: data,
  };
}

export function loadPaymentPlans(cursos) {
  return {
    type: '@newContract/LOAD_PAYMENT_PLANS',
    payload: { cursos },
  };
}

export function loadPaymentPlansSuccess(data) {
  return {
    type: '@newContract/LOAD_PAYMENT_PLANS_SUCCESS',
    payload: data,
  };
}

export function loadPaymentPlanDetails(id) {
  return {
    type: '@newContract/LOAD_PAYMENT_PLAN_DETAILS',
    payload: { id },
  };
}

export function loadPaymentPlanDetailsSuccess(data) {
  return {
    type: '@newContract/LOAD_PAYMENT_PLAN_DETAILS_SUCCESS',
    payload: data,
  };
}

export function addInstallment(data) {
  return {
    type: '@newContract/ADD_INSTALLMENT',
    payload: data,
  };
}

export function storePaymentPlan(data) {
  return {
    type: '@newContract/STORE_PAYMENT_PLAN',
    payload: data,
  };
}

export function storeContract(data) {
  return {
    type: '@newContract/STORE_CONTRACT',
    payload: data,
  };
}

export function storeContractSuccess() {
  return {
    type: '@newContract/STORE_CONTRACT_SUCCESS',
  };
}

export function loadLigaTurmas(data) {
  return {
    type: '@newContract/LOAD_LIGA_TURMAS',
    payload: data,
  };
}

export function loadLigaTurmasSuccess(data) {
  return {
    type: '@newContract/LOAD_LIGA_TURMAS_SUCCESS',
    payload: data,
  };
}
