import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { failureRequestVisitas, successRequestVisitas } from './actions';

export function* requestVisitas() {
  try {
    const response = yield call(api.get, 'visitas');

    yield put(successRequestVisitas(response.data));
  } catch (error) {
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(failureRequestVisitas());
  }
}

export default all([
  takeLatest('@visitas/GET_VISITAS_REQUEST', requestVisitas),
]);
