import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { DeleteOutline } from '@material-ui/icons';

import {
  addAnexo,
  deleteAnexo,
  updateStep,
} from '~/store/modules/new-contract/actions';

import IlustraAnexo from '~/assets/ilustra_anexo.svg';
import AnexoName from './AnexoName';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  titleSection: {
    margin: '20px 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '8px',
  },
  dropZone: {
    border: `4px dashed #BDC0D4`,
    borderRadius: '10px',
    height: '300px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#F7F8FA',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  gridFile: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    padding: '11px',
    borderRadius: '8px',
  },
  fileName: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(80, 166, 255, 1)',
  },
});

const schema = Yup.object().shape({
  size: Yup.number()
    .max(10000)
    .required(),
  type: Yup.string().oneOf(
    [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    'Tipo de arquivo inválido'
  ),
});

function Anexos({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const anexos = useSelector(state => state.newContract.anexos);
  const [arquivo, setArquivo] = React.useState(null);

  const onDrop = React.useCallback(acceptedFile => {
    const { size, type } = acceptedFile[0];
    schema
      .validate({ size: size / 1024, type })
      .then(() => {
        setArquivo(acceptedFile[0]);
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const saveFile = nome => {
    const newFile = Object.assign(arquivo, { nomeArquivo: nome });
    dispatch(addAnexo(newFile));
    setArquivo(null);
  };

  const handleDeleteFile = anexo => {
    dispatch(deleteAnexo(anexo));
  };

  return (
    <>
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography variant="h6" color="secondary">
            Adicione os documentos pertinentes a esse contrato
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.titleSection}>
          <div className={classes.dropZone}>
            <div className={classes.internDrop} {...getRootProps()}>
              <img src={IlustraAnexo} alt="Ilustração de anexo" />
              <input
                {...getInputProps()}
                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
              />
              <aside className={classes.aside}>
                <p className={classes.paside}>
                  Arraste seu arquivo ou{' '}
                  <u className={classes.pasideu}>busque</u>
                </p>
                <small>
                  Suportamos um arquivo por vez. Nos formatos .PDF, .DOC, PNG,
                  JPG menores que 10Mb.
                </small>
              </aside>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {anexos.map(anexo => (
              <Grid item xs={12} md={4} lg={3}>
                <div className={classes.gridFile}>
                  <Typography className={classes.fileName}>
                    {anexo.nomeArquivo}
                  </Typography>
                  <IconButton
                    onClick={() => handleDeleteFile(anexo)}
                    size="small"
                  >
                    <DeleteOutline />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep - 1))}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            Anterior
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep + 1))}
            variant="contained"
            className={classes.button}
            color="primary"
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
      <AnexoName file={arquivo} handleSave={saveFile} />
    </>
  );
}

Anexos.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Anexos;
