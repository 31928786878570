import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import { makeStyles } from '@material-ui/core';
import { addMonths, format, setDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { applyMask } from '~/utils/money';

const useStyles = makeStyles({
  root: {
    maxHeight: '550px',
    overflow: 'auto',
  },
});

function PlanoPagamentoGrid({ planoPagamento }) {
  const classes = useStyles();
  const [parcela, setParcela] = React.useState([]);
  const [material, setMaterial] = React.useState([]);
  const [matricula, setMatricula] = React.useState([]);

  const getTypeDescription = type => {
    switch (type) {
      case 'material':
        return 'Material';
      case 'matricula':
        return 'Matrícula';
      default:
        return 'Parcela';
    }
  };

  const formatarValoresByType = async type => {
    const pagamentos = [];

    planoPagamento[type].map(plano => {
      let dataParcela = new Date(`${plano.data}T03:00:00.000`);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < plano.quantidade; i++) {
        if (i > 0) {
          dataParcela = addMonths(dataParcela, 1);

          if (plano.demaisvencimentos) {
            dataParcela = setDate(dataParcela, plano.demaisvencimentos);
          }
        }

        pagamentos.push({
          parcela: i + 1,
          tipo: getTypeDescription(type),
          vencimento: dataParcela,
          valor: plano.valor,
          desconto: plano.desconto,
          descontoEmReais: plano.emreais,
        });
      }

      dataParcela = null;
    });

    return pagamentos;
  };

  const mountData = async () => {
    const parcelas = await formatarValoresByType('parcela');
    const materiais = await formatarValoresByType('material');
    const matriculas = await formatarValoresByType('matricula');

    setParcela(parcelas);
    setMaterial(materiais);
    setMatricula(matriculas);
  };

  const prepareCell = e => {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'vencimento') {
        const dataFormatada = format(new Date(e.value), 'dd/MM/yyyy', {
          locale: ptBR,
        });
        e.cellElement.innerText = dataFormatada;
      }

      if (e.column.dataField === 'valor') {
        let formattedValue = e.value;
        if (e.data.desconto && e.data.desconto > 0) {
          formattedValue = applyMask(
            e.data.descontoEmReais
              ? e.value - e.data.desconto
              : e.value * ((100 - e.data.desconto) / 100)
          );
        }

        e.cellElement.innerText = `R$ ${formattedValue}`;
      }
    }
  };

  React.useEffect(() => {
    mountData();
  }, [planoPagamento]); // eslint-disable-line

  return (
    <div className={classes.root}>
      <DataGrid
        id="gridContainer"
        dataSource={[...matricula, ...material, ...parcela]}
        showBorders
        noDataText="Defina um plano para visualizar as parcelas."
        onCellPrepared={e => {
          prepareCell(e);
        }}
      >
        <Paging enabled={false} />
        <Column dataField="parcela" alignment="left" caption="NÚMERO" />
        <Column dataField="tipo" alignment="center" caption="TIPO" />
        <Column
          dataField="vencimento"
          alignment="center"
          caption="VENCIMENTO"
        />
        <Column dataField="valor" alignment="center" caption="VALOR" />
      </DataGrid>
    </div>
  );
}

PlanoPagamentoGrid.propTypes = {
  planoPagamento: PropTypes.shape().isRequired,
};

export default PlanoPagamentoGrid;
