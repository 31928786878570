import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Box } from '@material-ui/core';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  MasterDetail,
  Button as DxButton,
} from 'devextreme-react/data-grid';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt';

import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import FormularioAtividade from '~/components/Atividades/ModalFormAtividade';
import Filtros from '~/components/Atividades/Filtros';
import MenuActions from '~/components/Atividades/MenuActions';

import { useFilters } from '~/hooks/filters';

import api from '~/services/api';
import { salvarAtividades } from '~/store/modules/atividades/actions';

import AtividadeTurmas from '../../components/Atividades/AtividadeTurmas';
import AtribuirAtividade from '../../components/Atividades/ModalAtribuirAtividades';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
    color: '#4D5884',
  },
  novoAtividade: {
    color: '#fff',
  },
  boxGrid: {
    padding: '20px 30px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

export default function Atividades() {
  const { filters } = useFilters();
  const atividades = useSelector(state => state.atividades.data);
  const cursos = useSelector(state => state.cursos.data);
  const lookup = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [openModalStore, setOpenModalStore] = useState(false);
  const [professores, setProfessores] = useState([]);

  useEffect(() => {
    const getProfessores = async () => {
      const response = await api.get('professores');
      setProfessores(response.data);
    };

    if (!professores.length) {
      getProfessores();
    }
  }, [professores]);

  const getAtividades = useCallback(async () => {
    const response = await api.get('/atividades', {
      params: filters.atividades,
    });
    response.data.map(atividade => {
      cursos.map(curso => {
        if (atividade.curso_id === curso.curso_id) {
          atividade.curso = curso.descricao;
        }
      });

      lookup.tipoAtividadeLancamento.map(tipo => {
        if (atividade.tipo_atividade === tipo.intkey) {
          atividade.tipo_atividade_descricao = tipo.descricao;
        }
      });

      lookup.tipoRespostaLancamento.map(tipo => {
        if (atividade.tipo_resposta === tipo.intkey) {
          atividade.tipo_resposta_descricao = tipo.descricao;
        }
      });
    });

    dispatch(salvarAtividades(response.data));
  }, [
    cursos,
    dispatch,
    filters.atividades,
    lookup.tipoAtividadeLancamento,
    lookup.tipoRespostaLancamento,
  ]);

  useEffect(() => {
    getAtividades();
  }, [filters]); // eslint-disable-line

  function handleCloseStore() {
    setOpenModalStore(false);
    getAtividades();
  }

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'data_criacao') {
        const dataFormatada = format(new Date(e.value), 'dd/MM/yyyy', {
          locale: ptBr,
        });
        e.cellElement.innerText = dataFormatada;
      }
    }
  }

  const renderActionCell = cellInfo => {
    return <MenuActions atividade={cellInfo.data} />;
  };

  return (
    <>
      <Breadcrumbs categorias={['Acadêmico']} funcionalidade="Atividades" />
      <div className={classes.title}>
        <Typography variant="h5">Atividades</Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.novoAtividade}
          onClick={() => setOpenModalStore(true)}
        >
          Adicionar atividade
        </Button>
      </div>
      <Box className={classes.boxGrid}>
        <Filtros professores={professores} />
        <DataGrid
          dataSource={atividades}
          allowColumnReordering
          showBorders
          hoverStateEnabled
          noDataText="Sem Atividades Cadastradas"
          onCellPrepared={e => {
            prepareCell(e);
          }}
        >
          <MasterDetail
            enabled
            component={e => (
              <AtividadeTurmas {...e} filtros={filters.atividades} />
            )}
          />
          <GroupPanel visible emptyPanelText="" />
          <Grouping autoExpandAll={false} />
          <Paging defaultPageSize={200} />
          <Pager showInfo infoText="Página {0} de {1} ({2} atividades)" />

          <Column caption="ID" dataField="id" visible={false} />
          <Column caption="CURSO" dataField="curso" groupIndex={0} />
          <Column caption="MATÉRIA" dataField="materia" groupIndex={1} />
          <Column caption="AULA" dataField="aula" groupIndex={2} />
          <Column caption="TIPO" dataField="tipo_atividade" />
          <Column caption="TÍTULO" dataField="titulo" />
          <Column caption="DESCRIÇÃO" dataField="descricao" />
          <Column
            caption="DATA CRIAÇÃO DA ATIVIDADE"
            dataField="data_criacao"
          />
          <Column caption="CONTEÚDOS" dataField="conteudos" />
          <Column type="buttons" visible={false}>
            <DxButton
              render={e => {
                return <AtribuirAtividade atividade_id={e.data.atividade_id} />;
              }}
            />
          </Column>
          <Column
            caption="AÇÕES"
            cellRender={renderActionCell}
            alignment="center"
          />
        </DataGrid>
      </Box>
      {openModalStore && (
        <FormularioAtividade
          open={openModalStore}
          handleClose={handleCloseStore}
        />
      )}
    </>
  );
}
