import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';
import { toast } from 'react-toastify';

import history from '~/services/history';
import { requestPautas } from '~/store/modules/pautas/actions';

import ModalSelectTurma from '~/components/Pautas/ModalSelectTurma';
import ModalChamada from '~/components/Pautas/ModalChamada';
import GridAulas from '~/components/Pautas/GridAulas';

import { useStyles } from './styles';

export default function Pautas() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { turmaSelecionada, materiaSelecionada, filtros } = useSelector(
    state => state.pautas
  );
  const { pauta } = useSelector(state => state.permissoes.acessos);
  const [openModal, setOpenModal] = useState(false);

  const requestAulas = async () => {
    dispatch(
      requestPautas({
        filtros: filtros.data,
        turma: turmaSelecionada.turma_id,
        materia: materiaSelecionada.materia_id,
      })
    );
  };

  useEffect(() => {
    setOpenModal(false);
  }, [materiaSelecionada]);

  useEffect(() => {
    if (turmaSelecionada && materiaSelecionada) {
      requestAulas();
    }

    if (!pauta.exibir) {
      toast.error('Permissão negada.');
      history.push('/');
    }
  }, [turmaSelecionada, materiaSelecionada, filtros]); // eslint-disable-line

  return (
    <>
      <ModalSelectTurma
        openModal={!turmaSelecionada || !materiaSelecionada || openModal}
      />
      {turmaSelecionada && materiaSelecionada && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              Pauta de Chamada - {turmaSelecionada.nome}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.buttons}>
            <Button
              variant="text"
              startIcon={<ImportExport />}
              color="primary"
              onClick={() => setOpenModal(true)}
              className={classes.btTurma}
            >
              Trocar de turma
            </Button>
            {turmaSelecionada && materiaSelecionada && pauta.cadastrar && (
              <ModalChamada />
            )}
          </Grid>
          <Grid item xs={12}>
            <GridAulas />
          </Grid>
        </Grid>
      )}
    </>
  );
}
