import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from 'devextreme-react';
import { Column, Paging, Selection } from 'devextreme-react/data-grid';

import {
  loadLigaTurmas,
  storeClassData,
  updateStep,
} from '~/store/modules/new-contract/actions';
import Filtros from './Filtros';
import FiltrosAplicados from './Filtros/FiltrosAplicados';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  gridTable: {
    maxHeight: '500px',
    overflow: 'auto',
  },
  titleSection: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '8px',
  },
});

function Turma({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosTurma = useSelector(state => state.newContract.dadosTurma);
  const turmas = useSelector(state => state.newContract.turmasLiga);
  const filtrosAplicados = useSelector(state => state.newContract.filtrosTurma);

  useEffect(() => {
    dispatch(loadLigaTurmas(filtrosAplicados));
  }, [filtrosAplicados]); // eslint-disable-line

  const [selectedItemKeys, setSelectedItemKeys] = React.useState([
    ...dadosTurma,
  ]);

  const onSelectionChanged = React.useCallback(
    data => {
      setSelectedItemKeys(data.selectedRowKeys);
    },
    [selectedItemKeys] // eslint-disable-line
  );

  const handleNext = () => {
    dispatch(storeClassData(selectedItemKeys));
    dispatch(updateStep(currentStep + 1));
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="h6" color="primary">
            Turmas
          </Typography>
          <Filtros />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="secondary">
            Filtros aplicados:
          </Typography>
          <FiltrosAplicados />
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          <DataGrid
            id="gridContainer"
            dataSource={turmas}
            showBorders
            selectedRowKeys={selectedItemKeys}
            onSelectionChanged={onSelectionChanged}
            noDataText="Nenhuma turma encontrada"
          >
            <Paging enabled={false} />
            <Selection mode="multiple" showCheckBoxesMode="always" />

            <Column dataField="status" caption="STATUS" />
            <Column dataField="nome" caption="TURMA" />
            <Column dataField="curso" caption="CURSO" />
            <Column dataField="inicio" caption="INÍCIO" />
            <Column dataField="diasaula" caption="DIAS DE AULA" />
            <Column dataField="sala" caption="SALA" />
            <Column dataField="horaini" caption="INÍCIO DA AULA" />
            <Column dataField="horafim" caption="TÉRMINO DA AULA" />
            <Column dataField="professor" caption="PROFESSOR" />
          </DataGrid>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep - 1))}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            Anterior
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            className={classes.button}
            color="primary"
            disabled={selectedItemKeys.length === 0}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

Turma.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Turma;
