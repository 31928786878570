import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Typography,
  Badge,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import {
  VideoLibrary,
  ListAltOutlined,
  AssignmentOutlined,
  // DescriptionOutlined,
  PeopleOutlineOutlined,
  KeyboardArrowLeft,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Image from 'material-ui-image';

import history from '~/services/history';

import Powered from '~/assets/powered_grey.svg';
import LogoF10 from '~/assets/logo_f10.svg';

import { changeOpenMenu } from '~/store/modules/layout/actions';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  section: {
    marginTop: '20px',
    letterSpacing: '2px',
    display: 'flex',
    justifyContent: 'center',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  beta: {
    background: theme.palette.warning.main,
    color: '#fff',
    fontSize: '10px',
    padding: '1px 4px',
    borderRadius: '200px',
    letterSpacing: '1px',
    marginLeft: '30px',
  },
  drawer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
}));

function MenuDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const variantPersistent = useMediaQuery(theme => theme.breakpoints.up('md'));
  const profile = useSelector(state => state.professor.profile);
  const acessos = useSelector(state => state.permissoes.acessos);
  const { menuOpen } = useSelector(state => state.layout);

  const [largeScreen, setIsLargeScreen] = useState(variantPersistent);

  useEffect(() => {
    setIsLargeScreen(variantPersistent);
    if (!variantPersistent) {
      dispatch(changeOpenMenu(false));
    }
  }, [variantPersistent]); // eslint-disable-line

  const academyFunctionalities = {
    videoaulas: {
      id: 1,
      icon: <VideoLibrary color="primary" />,
      path: '/videoaulas',
    },
    pauta: {
      id: 2,
      name: 'Pautas',
      icon: <AssignmentOutlined color="primary" />,
      path: '/pautas',
    },
    atividades: {
      id: 3,
      icon: <ListAltOutlined color="primary" />,
      path: '/atividades',
    },
  };

  const commercialFunctionalities = {
    visitas: {
      id: 4,
      icon: <PeopleOutlineOutlined color="primary" />,
      path: '/visitas',
    },
    // contratos: {
    //   icon: <DescriptionOutlined color="primary" />,
    //   path: '/contratos',
    // },
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <Typography
        variant="caption"
        color="secondary"
        className={classes.section}
      >
        ACADÊMICO
      </Typography>
      <List>
        {['Videoaulas', 'Atividades', 'Pauta'].map(functionalityName => {
          const functionality =
            academyFunctionalities[functionalityName.toLowerCase()];
          const exibir =
            !acessos[functionalityName.toLowerCase()] ||
            acessos[functionalityName.toLowerCase()].exibir;
          return (
            <>
              {exibir && (
                <ListItem
                  button
                  key={Math.random(0, 9999) + functionality.id}
                  onClick={() => history.push(functionality.path)}
                >
                  <ListItemIcon>{functionality.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Badge
                        badgeContent={
                          ['Pauta'].includes(functionalityName) && (
                            <Typography className={classes.beta}>
                              BETA
                            </Typography>
                          )
                        }
                      >
                        <Typography>
                          {functionality.name || functionalityName}
                        </Typography>
                      </Badge>
                    }
                  />
                </ListItem>
              )}
            </>
          );
        })}
      </List>
      <Typography
        variant="caption"
        color="secondary"
        className={classes.section}
      >
        COMERCIAL
      </Typography>
      <List>
        {['Visitas'].map(functionalityName => {
          const functionality =
            commercialFunctionalities[functionalityName.toLowerCase()];
          return (
            <ListItem
              button
              key={Math.random(0, 9999) + functionality.id}
              onClick={() => history.push(functionality.path)}
            >
              <ListItemIcon>{functionality.icon}</ListItemIcon>
              <ListItemText primary={functionalityName} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        open={menuOpen}
        onClose={() => dispatch(changeOpenMenu(false))}
        variant={largeScreen ? 'persistent' : 'temporary'}
      >
        <div className={classes.drawer}>
          <div>
            <div className={classes.drawerHeader}>
              <Image
                src={(profile && profile.grupo_img) || LogoF10}
                style={{
                  display: 'flex',
                  paddingTop: 'unset',
                  marginLeft: '11px',
                }}
                imageStyle={{
                  width: 'unset',
                  height: 'unset',
                  position: 'unset',
                  maxHeight: '60px',
                }}
              />

              {menuOpen && (
                <IconButton
                  color="secondary"
                  aria-label="open drawer"
                  onClick={() => {
                    dispatch(changeOpenMenu(!menuOpen));
                  }}
                  edge="start"
                >
                  <KeyboardArrowLeft />
                </IconButton>
              )}
            </div>
            {list('left')}
          </div>
          <Image
            src={Powered}
            alt="F10"
            style={{
              backgroundColor: 'none',
              paddingTop: 'unset',
              display: 'flex',
              justifyContent: 'center',
            }}
            imageStyle={{
              position: 'unset',
              width: '160px',
              height: '50px',
            }}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default MenuDrawer;
