import React from 'react';
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  // LinearProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DoneAll, LockOutlined, BorderColor } from '@material-ui/icons';

import clsx from 'clsx';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import DadosPessoais from '~/components/NovoContrato/DadosPessoais';
import Contrato from '~/components/NovoContrato/Contrato';
import Turma from '~/components/NovoContrato/Turma';
import Financeiro from '~/components/NovoContrato/Financeiro';
import Observacao from '~/components/NovoContrato/Observacao';
import Conferencia from '~/components/NovoContrato/Conferencia';
import Anexos from '~/components/NovoContrato/Anexos';
import {
  resetNewContract,
  updateStep,
} from '~/store/modules/new-contract/actions';
import { confirm } from '~/components/Utils/Confirm';
import history from '~/services/history';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
    color: '#4D5884',
  },
  box: {
    padding: '20px 30px',
    background: '#fff',
    borderRadius: '8px',
    position: 'relative',
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  tab: {
    color: '#fff',
  },
  doneTab: {
    background: theme.palette.success.main,
  },
  currentTab: {
    background: theme.palette.primary.main,
  },
  appBar: {},
}));

function NovaVisita() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentStep, lastStepFinished, stepsDone } = useSelector(
    state => state.newContract
  );

  const handleClickCancel = async () => {
    if (await confirm('Ao cancelar, todas as informações serão perdidas.')) {
      dispatch(resetNewContract());
      history.push('/visitas');
    }
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 1:
        return <DadosPessoais handleCancel={handleClickCancel} />;
      case 2:
        return <Contrato handleCancel={handleClickCancel} />;
      case 3:
        return <Turma handleCancel={handleClickCancel} />;
      case 4:
        return <Financeiro handleCancel={handleClickCancel} />;
      case 5:
        return <Observacao handleCancel={handleClickCancel} />;
      case 6:
        return <Anexos handleCancel={handleClickCancel} />;
      case 7:
        return <Conferencia handleCancel={handleClickCancel} />;
      default:
        return 'Unknown step';
    }
  };

  const getIcon = step => {
    const nextStep = stepsDone.length ? stepsDone[stepsDone.length - 1] + 1 : 1;

    if (step < currentStep || stepsDone.includes(step)) {
      return <DoneAll />;
    }
    if (step === currentStep || nextStep === step) {
      return <BorderColor />;
    }
    return <LockOutlined />;
  };

  const getTabClass = step => {
    const nextStep = stepsDone.length ? stepsDone[stepsDone.length - 1] + 1 : 1;

    if (step < currentStep || stepsDone.includes(step)) {
      return classes.doneTab;
    }
    if (step === currentStep || nextStep === step) {
      return classes.currentTab;
    }
    return '';
  };

  const handleClick = step => {
    const nextStep = stepsDone.length ? stepsDone[stepsDone.length - 1] + 1 : 1;

    if (
      step <= lastStepFinished ||
      stepsDone.includes(step) ||
      step === nextStep
    ) {
      dispatch(updateStep(step));
    }
  };

  return (
    <>
      <Breadcrumbs
        categorias={['Comercial', 'Contratos']}
        funcionalidade="Novo Contrato"
      />
      <div className={classes.title}>
        <Typography variant="h5">Novo Contrato</Typography>
      </div>
      <Box className={classes.box}>
        <AppBar position="sticky" className={classes.appBar} color="secondary">
          <Tabs
            variant="fullWidth"
            value={currentStep}
            indicatorColor="primary"
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            <Tab
              label="Pessoas"
              icon={getIcon(1)}
              className={clsx(classes.tab, getTabClass(1))}
              onClick={() => handleClick(1)}
            />
            <Tab
              label="Contrato"
              icon={getIcon(2)}
              className={clsx(classes.tab, getTabClass(2))}
              onClick={() => handleClick(2)}
            />
            <Tab
              label="Turma"
              icon={getIcon(3)}
              className={clsx(classes.tab, getTabClass(3))}
              onClick={() => handleClick(3)}
            />
            <Tab
              label="Financeiro"
              icon={getIcon(4)}
              className={clsx(classes.tab, getTabClass(4))}
              onClick={() => handleClick(4)}
            />
            <Tab
              label="Observação"
              icon={getIcon(5)}
              className={clsx(classes.tab, getTabClass(5))}
              onClick={() => handleClick(5)}
            />
            <Tab
              label="Anexos"
              icon={getIcon(6)}
              className={clsx(classes.tab, getTabClass(6))}
              onClick={() => handleClick(6)}
            />
            <Tab
              label="Conferência"
              icon={getIcon(7)}
              className={clsx(classes.tab, getTabClass(7))}
              onClick={() => handleClick(7)}
            />
          </Tabs>
        </AppBar>
        <div>{getStepContent()}</div>
      </Box>
    </>
  );
}

export default NovaVisita;
