import produce from 'immer';

const dadosAlunoInitialState = {
  outro_titular: false,
  cgc_cpf: '',
  nome: '',
  ie_rg: '',
  nascimento: '',
  sexo: '',
  nomeMae: '',
  nomePai: '',
  escolaridade: null,
  profissao: null,
  email: '',
  telefone: '',
  celular: '',
  comercial: '',
  cep: '',
  logradouro: '',
  endComplemento: '',
  bairro: '',
  localidade: '',
  estado: '',
};

const dadosTitularInitialState = {
  cgc_cpf: '',
  nome: '',
  ie_rg: '',
  nascimento: '',
  nomeMae: '',
  nomePai: '',
  escolaridade: null,
  profissao: null,
  email: '',
  telefone: '',
  celular: '',
  comercial: '',
  cep: '',
  logradouro: '',
  endComplemento: '',
  bairro: '',
  localidade: '',
  estado: '',
};

const dadosContratoInitialState = {
  cursos: [],
  tipo_contrato: '',
  modalidade_contrato: '',
  evento: '',
  vendedor: '',
  divulgador: '',
  materiasSelecionadas: [],
  materiasGratuitasSelecionadas: [],
  livrosSelecionados: [],
};

const dadosPagamento = {
  valor: '0',
  data: '',
  diavencimento: null,
  alteravencimento: '',
  emreais: false,
  desconto: '0',
  descontominimo: 0,
  descontomaximo: 0,
  quantidade: 0,
  bancocobranca: null,
  cc_bancocobranca: null,
  cc_parcelamento_max: null,
};

const dadosFinanceiroInitialState = {
  planopagamento_id: null,
  matricula: [
    {
      ...dadosPagamento,
      type: 'matricula',
    },
  ],
  material: [
    {
      ...dadosPagamento,
      type: 'material',
    },
  ],
  parcela: [
    {
      ...dadosPagamento,
      demaisvencimentos: null,
      diautil: true,
      type: 'parcela',
    },
  ],
};

const filtrosTurmaInitialState = {
  cursos: [],
  dias: [],
  periodos: [],
  status: [],
  tipo: [],
};

const INITIAL_STATE = {
  currentStep: 1,
  lastStepFinished: null,
  dadosAluno: dadosAlunoInitialState,
  dadosTitular: dadosTitularInitialState,
  dadosContrato: dadosContratoInitialState,
  anexos: [],
  dadosTurma: [],
  dadosFinanceiro: dadosFinanceiroInitialState,
  dadosObservacao: {
    observacoes_gerais: '',
    observacoes_cobranca: '',
  },
  visita: {},
  assinatura: null,
  materias: [],
  livros: [],
  materiasGratuitas: [],
  filtrosTurma: filtrosTurmaInitialState,
  planoPagamentoLoaded: false,
  savedSuccess: false,
  paymentPlans: null,
  turmasLiga: [],
  stepsDone: [],
};

const STEP_FINANCEIRO = 4;
const TIPO_CONTRATO_BOLSA = 3;

export default function newContract(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@newContract/SAVE_SIGNATURE': {
        draft.assinatura = action.payload.assinatura;
        break;
      }
      case '@newContract/ERASE_SIGNATURE': {
        draft.assinatura = null;
        break;
      }
      case '@newContract/UPDATE_LAST_STEP_FINISHED': {
        draft.lastStepFinished = action.payload.step;
        break;
      }
      case '@newContract/UPDATE_STEP': {
        if (
          !draft.stepsDone.includes(draft.currentStep) &&
          action.payload.step > draft.currentStep
        ) {
          draft.stepsDone.push(draft.currentStep);
        }

        if (
          action.payload.step === STEP_FINANCEIRO &&
          draft.dadosContrato.tipo_contrato === TIPO_CONTRATO_BOLSA
        ) {
          draft.stepsDone.push(STEP_FINANCEIRO);
          draft.currentStep = action.payload.step + 1;
          break;
        }

        draft.currentStep = action.payload.step;
        break;
      }
      case '@newContract/STORE_PERSONAL_DATA': {
        draft.dadosAluno = action.payload.aluno;
        draft.dadosTitular = action.payload.titular;
        break;
      }
      case '@newContract/STORE_CONTRACT_DATA': {
        draft.dadosContrato = action.payload;
        break;
      }
      case '@newContract/STORE_CLASS_DATA': {
        draft.dadosTurma = action.payload;
        break;
      }
      case '@newContract/STORE_FINANCIAL_DATA': {
        draft.dadosFinanceiro = action.payload;
        break;
      }
      case '@newContract/STORE_OBSERVATION_DATA': {
        draft.dadosObservacao = action.payload;
        break;
      }
      case '@newContract/STORE_VISITA_DATA': {
        draft.visita = action.payload;
        draft.dadosAluno.apelido = action.payload.apelido || '';
        draft.dadosAluno.nome = action.payload.apelido || '';
        draft.dadosAluno.celular = action.payload.celular || '';
        draft.dadosAluno.telefone_comercial = action.payload.telefone || '';
        break;
      }
      case '@newContract/ADD_ANEXO': {
        draft.anexos.push(action.payload);
        break;
      }
      case '@newContract/DELETE_ANEXO': {
        draft.anexos = draft.anexos.filter(
          anexo => anexo.name !== action.payload.name
        );
        break;
      }
      case '@newContract/STORE_MATERIAS': {
        draft.materias = action.payload;
        break;
      }
      case '@newContract/STORE_LIVROS': {
        draft.livros = action.payload;
        break;
      }
      case '@newContract/UPDATE_MATERIAS_SELECIONADAS': {
        draft.dadosContrato.materiasSelecionadas = action.payload;
        break;
      }
      case '@newContract/UPDATE_LIVROS_SELECIONADOS': {
        draft.dadosContrato.livrosSelecionados = action.payload;
        break;
      }
      case '@newContract/STORE_MATERIAS_GRATUITAS': {
        draft.materiasGratuitas = action.payload;
        break;
      }
      case '@newContract/UPDATE_FILTROS_TURMA': {
        draft.filtrosTurma = action.payload;
        break;
      }
      case '@newContract/LOAD_PAYMENT_PLANS_SUCCESS': {
        draft.paymentPlans = action.payload;
        break;
      }
      case '@newContract/ADD_INSTALLMENT':
      case '@newContract/LOAD_PAYMENT_PLAN_DETAILS_SUCCESS': {
        draft.dadosFinanceiro = action.payload;
        break;
      }
      case '@newContract/STORE_PAYMENT_PLAN': {
        draft.planoPagamentoLoaded = true;
        draft.dadosFinanceiro.quantidade_parcelas =
          action.payload.parcelaquantidade;
        draft.dadosFinanceiro.desconto_parcelas =
          action.payload.parceladesconto;
        draft.dadosFinanceiro.valor_parcelas = action.payload.parcelavalor;
        draft.dadosFinanceiro.primeiro_vencimento = action.payload.parceladata;
        break;
      }
      case '@newContract/LOAD_LIGA_TURMAS_SUCCESS': {
        draft.turmasLiga = action.payload;
        break;
      }
      case '@newContract/RESET_NEW_CONTRACT':
      case '@newContract/STORE_CONTRACT_SUCCESS':
      case '@auth/SIGN_OUT':
      case '@unidade/UNIDADE_SELECIONADA_FAILURE':
      case '@unidade/UNIDADE_SELECIONADA_SUCCESS': {
        draft.currentStep = 1;
        draft.lastStepFinished = null;
        draft.stepsDone = [];
        draft.dadosAluno = dadosAlunoInitialState;
        draft.dadosTitular = dadosTitularInitialState;
        draft.dadosContrato = dadosContratoInitialState;
        draft.dadosTurma = [];
        draft.dadosFinanceiro = dadosFinanceiroInitialState;
        draft.dadosObservacao = {
          observacoes_gerais: '',
          observacoes_cobranca: '',
        };
        draft.visita = {};
        draft.anexos = [];
        draft.assinatura = null;
        draft.currentStep = 1;
        draft.lastStepFinished = null;
        draft.materias = [];
        draft.livros = [];
        draft.materiasGratuitas = [];
        draft.filtrosTurma = filtrosTurmaInitialState;
        draft.savedSuccess = false;
        break;
      }
      default:
    }
  });
}
