import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';

import {
  storeObservationData,
  updateStep,
} from '~/store/modules/new-contract/actions';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleSection: {
    margin: '20px 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '8px',
  },
});

function Observacao({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosObservacao = useSelector(
    state => state.newContract.dadosObservacao
  );

  const formik = useFormik({
    initialValues: dadosObservacao,
    onSubmit: values => {
      dispatch(storeObservationData(values));
      dispatch(updateStep(currentStep + 1));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography variant="h6" color="secondary">
            Preencha os campos abaixo com observações sobre esse contrato
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="body1" color="primary">
            Observações gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="observacoes_gerais"
            label="Observações gerais"
            variant="filled"
            fullWidth
            multiline
            rows={4}
            value={formik.values.observacoes_gerais}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.observacoes_gerais &&
              Boolean(formik.errors.observacoes_gerais)
            }
            helperText={
              formik.touched.observacoes_gerais &&
              formik.errors.observacoes_gerais
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="body1" color="primary">
            Observações de cobrança
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="observacoes_cobranca"
            label="Observações de cobrança"
            variant="filled"
            fullWidth
            multiline
            rows={4}
            value={formik.values.observacoes_cobranca}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.observacoes_cobranca &&
              Boolean(formik.errors.observacoes_cobranca)
            }
            helperText={
              formik.touched.observacoes_cobranca &&
              formik.errors.observacoes_cobranca
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep - 1))}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            Anterior
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
            color="primary"
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

Observacao.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Observacao;
